<template>
  <div>
    <article style="padding-top: 0.7em">
      <v-layout row wrap>
        <v-flex v-if="createdOn" xs6>
          <v-layout v-if="createdOn" row align="center" no-gutters>
            <v-flex class="flex-grow-0" xs2>
              <v-icon small left>calendar_today</v-icon>
            </v-flex>

            <v-flex class="flex-grow-1">
              {{ tripDateRange }}
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs6>
          <v-layout row align="center" no-gutters>
            <v-flex class="flex-grow-0" xs2>
              <v-icon left>person</v-icon>
            </v-flex>
            <v-flex>{{ tripPassengerCount }} Passengers</v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs6>
          <v-layout row align="center" no-gutters>
            <v-flex class="flex-grow-0" xs2>
              <v-icon
                left
                style="width: 16px; margin-right: 9px; margin-left: 3px"
              >
                $vuetify.icons.tripType
              </v-icon>
            </v-flex>
            <v-flex>{{ tripType }}</v-flex>
          </v-layout>
        </v-flex>
        <v-spacer />
        <v-flex xs6>
          <v-layout no-gutters align="start">
            <v-flex class="flex-grow-0" xs2>
              <v-icon left style="margin-top: 1px">directions_bus</v-icon>
            </v-flex>
            <v-flex>
              <span
                v-for="(content, vehicleIndex) in tripVehicles"
                :key="vehicleIndex"
                style="text-align: left"
              >
                {{ content | vehiclesPretty }}
                <br />
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </article>

    <article
      v-if="$vuetify.breakpoint.mdAndUp && customerNotes && tripDescription"
    >
      <h4>Trip Notes</h4>
      {{ tripDescription || 'No notes provided.' }}
    </article>

    <br />

    <Itinerary :has-recurrence="trip.hasRecurrence" :stops="tripAllStops" />
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import Itinerary from '@/components/CharterUpItinerary.vue'
import { pluralize } from '@/utils/string'

export default {
  components: {
    Itinerary,
  },
  filters: {
    vehiclesPretty(vehicle) {
      const label = pluralize(vehicle?.quantity, vehicle?.vehicleType?.label)
      return `${vehicle?.quantity} ${label}`
    },
    cityPretty(stop) {
      const city = stop?.address?.city ? `${stop?.address?.city}, ` : ''

      return `${city}${stop?.address?.state}`
    },
    pluralize,
  },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      default: null,
    },
    createdOn: {
      type: String,
      default: null,
    },
    companyName: {
      type: String,
      default: null,
    },
    to: {
      type: Object,
      default: null,
    },
    itinerary: {
      type: Boolean,
    },
    customerNotes: {
      type: Boolean,
    },
    map: {
      type: Boolean,
    },
  },
  data() {
    return {
      imageModal: false,
    }
  },
  computed: {
    firstStop() {
      return this.trip?.stops?.[0]
    },
    secondStop() {
      return this.trip?.stops?.[1]
    },
    stopLength() {
      return this.trip?.stops?.length
    },
    lastStop() {
      return this.trip?.stops?.[this.stopLength - 1]
    },
    tripAllStops() {
      return this.trip?.stops
    },
    tripDescription() {
      return this.trip?.description
    },
    tripCompanyName() {
      return this.trip?.company.name
    },
    tripPassengerCount() {
      return this.trip?.passengerCount
    },
    tripTotal() {
      return this.total
    },
    tripCharges() {
      return this.trip?.charges
    },
    tripVehicles() {
      return this.trip?.vehicles
    },
    tripType() {
      return this.trip?.tripType?.label
    },
    tripDateRange() {
      let startDay = this.firstStop.pickupDatetime
      let endDay =
        this.recurrenceEndDate ||
        this.lastStop?.dropoffDatetime ||
        this.lastStop?.pickupDatetime
      const startZone = this.firstStop?.address?.timeZone
      const endZone = this.recurrenceEndDate
        ? this.firstStop?.address?.timeZone
        : this.lastStop?.address?.timeZone

      try {
        startDay = DateTime.fromISO(startDay, {
          zone: startZone,
        }).toFormat('MM/dd')
      } catch (err) {
        return ''
      }
      try {
        endDay = DateTime.fromISO(endDay, {
          zone: endZone,
        }).toFormat('MM/dd')
      } catch (err) {
        endDay = null
      }
      if (startDay === endDay || !endDay) return startDay
      return `${startDay} -  ${endDay} ${
        this.trip.hasRecurrence ? '(' + this.trip.recurrenceTripCount + ')' : ''
      }`
    },
    recurrenceEndDate() {
      return this.trip?.recurrences?.[0]?.endDate || this.trip.startDate
    },
  },
  methods: {
    onClick() {
      this.$router.push(this.to)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.v-icon {
  width: 20px;
}
</style>
