var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shofur-footer"},[_c('div',{staticClass:"shofur-footer__primary"},[_c('div',{staticClass:"shofur-footer__primary__inner container"},[_c('div',{staticClass:"shofur-footer__main"},[_c('div',{staticClass:"shofur-footer__links"},[_c('div',{staticClass:"shofur-footer__link-section"},[_vm._m(0),_c('div',{staticClass:"shofur-footer__link-section__list"},_vm._l((_vm.navigation.internalLinks),function(internalLink,internalIdx){return _c('a',{key:internalIdx,staticClass:"shofur-footer__link",attrs:{"href":internalLink.url}},[_vm._v(" "+_vm._s(internalLink.label)+" ")])}),0)]),_c('div',{staticClass:"shofur-footer__link-section"},[_vm._m(1),_c('div',{staticClass:"shofur-footer__link-section__list"},_vm._l((_vm.citiesLimited
                  ? _vm.navigation.cityLinks.slice(0, 5)
                  : _vm.navigation.cityLinks),function(link,index){return _c('a',{key:index,staticClass:"shofur-footer__link",attrs:{"href":link.url}},[_vm._v(" "+_vm._s(link.label)+" ")])}),0),_c('div',{staticClass:"shofur-footer__link-section__toggle"},[(_vm.citiesLimited)?_c('span',{on:{"click":function($event){_vm.citiesLimited = false}}},[_vm._v(" + More ")]):_vm._e(),(!_vm.citiesLimited)?_c('span',{on:{"click":function($event){_vm.citiesLimited = true}}},[_vm._v(" - Less ")]):_vm._e()])]),_c('div',{staticClass:"shofur-footer__link-section"},[_vm._m(2),_c('div',{staticClass:"shofur-footer__link-section__list"},_vm._l((_vm.statesLimited
                  ? _vm.navigation.stateLinks.slice(0, 5)
                  : _vm.navigation.stateLinks),function(stateLink,stateIdx){return _c('a',{key:stateIdx,staticClass:"shofur-footer__link",attrs:{"href":stateLink.url}},[_vm._v(" "+_vm._s(stateLink.label)+" ")])}),0),_c('div',{staticClass:"shofur-footer__link-section__toggle"},[(_vm.statesLimited)?_c('span',{on:{"click":function($event){_vm.statesLimited = false}}},[_vm._v(" + More ")]):_vm._e(),(!_vm.statesLimited)?_c('span',{on:{"click":function($event){_vm.statesLimited = true}}},[_vm._v(" - Less ")]):_vm._e()])])]),_c('div',{staticClass:"shofur-footer__other"},[_vm._m(3),_c('div',{staticClass:"shofur-footer__link-section"},[_vm._m(4),_c('div',{staticClass:"shofur-footer__link-section__body"},[_vm._m(5),_c('div',{staticStyle:{"margin-top":"15px"}},[_c('StarRating',{attrs:{"rating":4.5,"half-increments":"","read-only":"","dense":"","show-rating":false,"max-rating":5,"star-size":15,"border-width":0,"padding":3,"glow":0,"glow-color":"none","border-color":"none"}}),_vm._m(6)],1)])])])])])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shofur-footer__link-section__name"},[_c('h3',[_vm._v("About Shofur")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shofur-footer__link-section__name"},[_c('h3',[_vm._v("Locations by City")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shofur-footer__link-section__name"},[_c('h3',[_vm._v("Locations by State")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shofur-footer__link-section"},[_c('div',{staticClass:"shofur-footer__link-section__name"},[_c('h3',[_vm._v("Social")])]),_c('div',{staticClass:"shofur-footer__link-section__social-list"},[_c('div',{staticClass:"shofur-footer__social-link"},[_c('a',{attrs:{"href":"https://twitter.com/shofur","target":"_blank"}},[_c('img',{attrs:{"src":"https://shofur.com/booking/img/new-img/ico-twitter.png"}})])]),_c('div',{staticClass:"shofur-footer__social-link"},[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/RideShofur"}},[_c('img',{attrs:{"src":"https://shofur.com/booking/img/new-img/ico-facebook.png"}})])]),_c('div',{staticClass:"shofur-footer__social-link"},[_c('a',{attrs:{"target":"_blank","href":" https://plus.google.com/b/110838633822395952308/+SHOFURAtlanta"}},[_c('img',{attrs:{"src":"https://shofur.com/booking/img/new-img/ico-googleplus.png"}})])]),_c('div',{staticClass:"shofur-footer__social-link"},[_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/shofur_bus/"}},[_c('img',{attrs:{"src":"https://shofur.com/booking/img/new-img/ico-instagram.png"}})])]),_c('div',{staticClass:"shofur-footer__social-link"},[_c('a',{attrs:{"target":"_blank","href":"https://www.linkedin.com/company/shofur"}},[_c('img',{attrs:{"src":"https://shofur.com/booking/img/new-img/ico-linkden.png"}})])]),_c('div',{staticClass:"shofur-footer__social-link"},[_c('a',{attrs:{"href":"https://www.pinterest.com/Shofur_Bus"}},[_c('img',{attrs:{"src":"https://shofur.com/booking/img/new-img/ico-pininterest.png"}})])]),_c('div',{staticClass:"shofur-footer__social-link"},[_c('a',{attrs:{"href":"https://www.youtube.com/user/SHOFURBUS"}},[_c('img',{attrs:{"src":"https://shofur.com/booking/img/new-img/ico-youtube.png"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shofur-footer__link-section__name"},[_c('h3',[_vm._v("Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"shofur-footer__contact-link",staticStyle:{"color":"#ccc"},attrs:{"target":"_blank","href":"tel:18004368719"}},[_vm._v(" 1-800-436-8719 ")]),_c('br'),_c('div',{staticStyle:{"color":"gray"}},[_vm._v("Available 24/7")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"color":"#ccc"}},[_c('strong',[_vm._v("Overall Rating")]),_vm._v(" : "),_c('a',{staticStyle:{"color":"#00adf5"},attrs:{"href":"https://resellerratings.com/store/Shofur","target":"_blank"}},[_vm._v(" 9.25 out of 10 ")]),_vm._v(" from 29 unique reviews ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shofur-footer__secondary"},[_c('div',{staticClass:"shofur-footer__secondary__inner"},[_c('div',{staticClass:"shofur-footer__legal"}),_c('div',{staticClass:"shofur-footer__logo"},[_c('img',{staticClass:"shofur-footer__logo-image",attrs:{"src":require("@/assets/images/logo-shofur-icon.png")}})]),_c('div',{staticClass:"shofur-footer__copyright"})])])
}]

export { render, staticRenderFns }