<template>
  <v-footer v-show="$vuetify.breakpoint.smAndUp" dark class="charterup-footer">
    <div style="margin: 0 auto" class="footer-links">
      <v-layout justify-space-between>
        <v-flex>
          <a
            id="footer-link-join-our-team"
            href="https://www.charterup.com/careers"
            target="_blank"
          >
            Join Our Team
          </a>
        </v-flex>

        <v-flex>
          <a
            id="footer-link-contact-us"
            href="mailto:info@charterup.com?subject=CharterUP Web Contact"
            target="_blank"
          >
            Contact Us
          </a>
        </v-flex>

        <v-flex>
          <a
            id="footer-link-terms-and-conditions"
            href="https://www.charterup.com/terms-and-conditions"
            target="_blank"
          >
            Terms &amp; Conditions
          </a>
        </v-flex>

        <v-flex>
          <a
            id="footer-link-privacy-policy"
            href="https://www.charterup.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-divider />
      </v-layout>

      <v-layout class="footer-links-bottom">
        <v-flex>{{ $t('common.COPYRIGHT') }}</v-flex>
        <v-spacer />
        <v-layout flex>
          <v-flex>
            <a
              id="footer-link-facebook"
              href="https://www.facebook.com/charterup/"
              target="_blank"
            >
              <v-icon>$vuetify.icons.facebook</v-icon>
            </a>
          </v-flex>
          <v-flex>
            <a
              id="footer-link-instagram"
              href="https://www.instagram.com/charterup/"
              target="_blank"
            >
              <v-icon>$vuetify.icons.instagram</v-icon>
            </a>
          </v-flex>
          <v-flex>
            <a
              id="footer-link-twitter"
              href="https://twitter.com/charter_up"
              target="_blank"
            >
              <v-icon>$vuetify.icons.twitter</v-icon>
            </a>
          </v-flex>
          <v-flex>
            <a
              id="footer-link-linkedin"
              href="https://www.linkedin.com/company/charterup/"
              target="_blank"
            >
              <v-icon>$vuetify.icons.linkedin</v-icon>
            </a>
          </v-flex>
        </v-layout>
      </v-layout>
    </div>
  </v-footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.coachrail .v-divider {
  margin-bottom: 0;
}
.charterup-footer {
  margin-top: 40px;
  font-family: 'Airbnb Cereal App Book' !important;
  height: 134px !important;

  .footer-links {
    width: 556px;
    font-size: 14px;

    > * {
      margin: 20px 0;
    }

    a {
      text-decoration: none;
      color: $white !important;
    }

    i,
    .v-icon {
      font-size: 16px !important;
      color: $white;
    }

    .footer-links-bottom {
      color: $white;
      > a {
        font-size: 16px;
        color: $white;
        + a {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
