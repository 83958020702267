<template>
  <v-container style="max-width: 1000px">
    <header>
      <v-layout full-width align-center :column="$vuetify.breakpoint.xs">
        <v-flex>
          <img :src="logoForCompany" width="200" />
        </v-flex>
        <v-spacer grow />
      </v-layout>

      <br />
      <br />

      <h1 class="text-xs-center">Checkout</h1>
    </header>

    <section class="checkout-provider--quote-info-box">
      <v-flex>
        <b>Quote ID:</b>
        {{ quote.quoteId }}
      </v-flex>
      <v-flex v-if="quote.trips.length > 1">
        <b>Trip Itineraries:</b>
        {{ quote.trips.length }}
        <span v-if="quoteHasRecurrence">, with recurrences</span>
      </v-flex>
      <v-flex v-if="quoteTotalTrips > 1">
        <b>Total Events:</b>
        {{ quoteTotalTrips }}
      </v-flex>
    </section>

    <section class="checkout-provider-contact-info">
      <v-layout wrap>
        <v-flex xs12 sm6>
          <h3>Billing Contact</h3>
          <br />
          <v-layout wrap>
            <v-flex xs12>
              <b style="margin-bottom: 2px">
                {{ `${quote.customer.firstName} ${quote.customer.lastName}` }}
              </b>
            </v-flex>
            <v-flex v-if="customerAccountName" xs12>
              <p style="margin-bottom: 2px">{{ customerAccountName }}</p>
            </v-flex>
            <v-flex xs12>
              <p style="margin-bottom: 2px">
                <a :href="`mailto:${quote.customer.email}`">
                  {{ quote.customer.email }}
                </a>
              </p>
            </v-flex>
            <v-flex xs12>
              <p>
                <a :href="`tel:${quote.customer.phone}`">
                  {{ phoneFormatFilter(quote.customer.phone) }}
                </a>
              </p>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 sm6>
          <h3>Company Information</h3>
          <br />
          <v-layout>
            <v-flex xs12>
              <h6>{{ quote.company.name }}</h6>
              <div>
                <span>
                  {{ quote.company.address.street1 }}
                </span>
                <span v-if="quote.company.address.street2">
                  {{ quote.company.address.street2 }}
                </span>
                <span>
                  {{ quote.company.address.city }},
                  {{ quote.company.address.state }}
                  {{ quote.company.address.postalCode }}
                </span>
              </div>
              <a :href="`mailto:${quote.company.email}`">
                {{ quote.company.email }}
              </a>
              <br />
              <a :href="`tel:${quote.company.phone}`">
                {{ phoneFormatFilter(quote.company.phone) }}
              </a>
              <span v-if="quote.company.websiteUrl">
                <br />
                <a :href="quote.company.websiteUrl">
                  {{ quote.company.websiteUrl }}
                </a>
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </section>

    <section class="checkout-provider-trip-info">
      <v-expansion-panel
        expand
        :value="quote ? quote.trips.map((trip) => 1) : null"
        class="elevation-0"
      >
        <v-expansion-panel-content
          v-for="(trip, tripIndex) in quote.trips"
          :key="`checkout-form-trip-${tripIndex}`"
        >
          <template #header>
            <div>
              <h3>Trip #{{ tripIndex + 1 }}</h3>
            </div>
          </template>

          <div class="checkout-provider-trip-info-content">
            <div class="checkout-provider-trip-info-content-summary">
              <v-layout>
                <v-flex>
                  <h6>Trip Type</h6>
                  <p>{{ trip.tripType.label }}</p>
                </v-flex>
                <v-flex v-if="!trip.hasRecurrence">
                  <h6>Passengers</h6>
                  <p>{{ trip.passengerCount }}</p>
                </v-flex>
                <v-flex v-if="!trip.hasRecurrence">
                  <h6>Drivers</h6>
                  <p>{{ trip.requiredDrivers }}</p>
                </v-flex>
                <v-flex>
                  <h6>Vehicles</h6>
                  <p>
                    <span
                      v-for="(vehicle, vehicleIndex) in trip.vehicles"
                      :key="`trip-${tripIndex}-vehicle-${vehicleIndex}`"
                    >
                      {{ vehicle.quantity }}
                      {{
                        pluralize(vehicle.quantity, vehicle.vehicleType.label)
                      }}
                      <br />
                    </span>
                  </p>
                </v-flex>
                <v-flex>
                  <h6>Payment Type</h6>
                  <p>{{ trip.paymentType.label }}</p>
                </v-flex>
                <v-flex>
                  <h6>Start Date</h6>
                  <p>
                    {{
                      itineraryDate(
                        trip.startDate,
                        trip.stops[0].address.timeZone
                      )
                    }}
                  </p>
                </v-flex>
                <v-flex v-if="trip.hasRecurrence">
                  <h6>End Date</h6>
                  <p>
                    {{
                      itineraryDate(
                        recurrenceEndDate(trip),
                        trip.stops[0].address.timeZone
                      )
                    }}
                  </p>
                </v-flex>
                <v-flex v-if="trip.hasRecurrence" xs2>
                  <h6>Repeat</h6>
                  <p>{{ trip.recurrenceTripCount }}</p>
                </v-flex>
              </v-layout>
            </div>

            <div class="checkout-provider-trip-info-content-itinerary">
              <h3>Itinerary</h3>

              <v-layout
                v-for="(stop, stopIndex) in trip.stops"
                :key="`checkout-form-trip-${tripIndex}-stop-${stopIndex}`"
                row
                wrap
              >
                <v-flex xs1>
                  <span class="itinerary-number">{{ stopIndex + 1 }}</span>
                </v-flex>

                <v-flex xs6>
                  <h6>Address</h6>
                  <p>
                    <span v-if="stop.address.title">
                      {{ stop.address.title }}
                      <br />
                    </span>
                    <span>{{ stop.address.name }}</span>
                  </p>
                  <p
                    v-if="
                      (stop.stopNotes &&
                        stop.stopNotes[0] &&
                        (stop.stopNotes[0].html || stop.stopNotes[0].note)) ||
                      stop.notes
                    "
                    class="stop-notes-title"
                  >
                    Notes
                    <CRIcon
                      v-if="stop.notes && stop.notes.length"
                      view-box="0 3 18 11"
                      icon-name="keyboard-arrow"
                      :width="18"
                      :height="18"
                      color="primary"
                      :class="`icon-arrow ${
                        stopNotes[tripIndex].stops[stopIndex].active
                          ? 'icon-arrow--flipped'
                          : ''
                      }`"
                      @click.native="toggleStopNotes(tripIndex, stopIndex)"
                    >
                      keyboard_arrow_down
                    </CRIcon>
                  </p>
                  <div
                    v-if="stopNotes[tripIndex].stops[stopIndex].active"
                    class="stop-notes"
                  >
                    <p
                      v-if="stop.stopNotes && stop.stopNotes[0]"
                      v-html="stop.stopNotes[0].html || stop.stopNotes[0].note"
                    />
                    <p v-else>{{ stop.notes }}</p>
                  </div>
                </v-flex>

                <v-flex>
                  <div v-if="stop.dropoffDatetime" xs2>
                    <h6 style="text-align: center">Dropoff</h6>
                    <p v-if="trip.hasRecurrence" style="text-align: center">
                      {{
                        itineraryTime(
                          stop.dropoffDatetime,
                          stop.address.timeZone
                        )
                      }}
                    </p>
                    <p v-else style="text-align: center">
                      {{
                        itineraryDate(
                          stop.dropoffDatetime,
                          stop.address.timeZone
                        )
                      }}
                      <br />
                      {{
                        itineraryTime(
                          stop.dropoffDatetime,
                          stop.address.timeZone
                        )
                      }}
                    </p>
                  </div>
                </v-flex>

                <v-flex>
                  <div
                    v-if="stop.pickupDatetime"
                    :style="stop.notes ? `margin-left: 20px` : ''"
                    xs2
                  >
                    <h6 style="text-align: center">Pickup</h6>
                    <p v-if="trip.hasRecurrence" style="text-align: center">
                      {{
                        itineraryTime(
                          stop.pickupDatetime,
                          stop.address.timeZone
                        )
                      }}
                    </p>
                    <p v-else style="text-align: center">
                      {{
                        itineraryDate(
                          stop.pickupDatetime,
                          stop.address.timeZone
                        )
                      }}
                      <br />
                      {{
                        itineraryTime(
                          stop.pickupDatetime,
                          stop.address.timeZone
                        )
                      }}
                    </p>
                  </div>
                </v-flex>
              </v-layout>
            </div>

            <div
              v-if="trip.description"
              class="checkout-provider-trip-info-content-description"
            >
              <h3>Trip Notes</h3>
              <p v-html="previousTripNotes.customerNotes || trip.description" />
            </div>

            <div
              v-if="trip.tripContact"
              class="checkout-provider-trip-info-content-contact"
            >
              <h3>Trip Contact</h3>
              <p>
                <span>
                  {{ trip.tripContact.firstName }}
                  {{ trip.tripContact.lastName }}
                </span>
                <span>
                  <a :href="`tel:${trip.tripContact.phone}`">
                    {{ phoneFormatFilter(trip.tripContact.phone) }}
                  </a>
                </span>
                <span
                  v-if="
                    trip.tripContact.email &&
                    !trip.tripContact.email.includes('tripContact')
                  "
                >
                  <a :href="`mailto:${trip.tripContact.email}`">
                    {{ trip.tripContact.email }}
                  </a>
                </span>
              </p>
            </div>
          </div>

          <div class="checkout-provider-trip-info-rates-charges-container">
            <div class="checkout-provider-trip-info-rates">
              <h3 v-if="trip.rates && trip.rates.length">Overage Rates</h3>

              <template v-for="(rate, rateIndex) in trip.rates">
                <div :key="`trip-rates-${rateIndex}`" class="trip-rate">
                  <span class="trip-rate-label">
                    {{ rate.rateType.label }}
                  </span>
                  <span v-if="trip.hasRecurrence" style="font-weight: 400">
                    per trip
                  </span>
                  <span class="rate-amount">
                    {{ currencyFilter(rate.amount) }}
                  </span>
                </div>
              </template>
            </div>
            <div class="checkout-provider-trip-info-charges">
              <h3>Trip Charges</h3>

              <template v-for="(charge, chargeIndex) in trip.charges">
                <div :key="`trip-charges-${chargeIndex}`" class="trip-charge">
                  <span class="trip-charge-label">
                    {{ charge.chargeType.label }}
                  </span>
                  <span v-if="trip.hasRecurrence" style="font-weight: 400">
                    per trip
                  </span>
                  <span v-if="charge.chargeType.key === 'processing_fees'">
                    ({{ getProcessingFeePercentage(trip) }}%)
                  </span>
                  <span class="charge-amount">
                    {{ currencyFilter(charge.amount) }}
                  </span>
                </div>
              </template>

              <div
                class="trip-charge trip-charge-total payment-box payment-box-total"
              >
                <span v-if="trip.hasRecurrence">Total per Trip</span>
                <span v-else>Trip Total</span>
                <span class="charge-amount">
                  {{ currencyFilter(trip.total) }}
                </span>
              </div>

              <template v-if="trip.paymentType.key === 'down_payment'">
                <div class="trip-charge payment-box payment-box-due-later">
                  <span>
                    Amount Due
                    {{
                      dateAsShortString(
                        trip.dueDate,
                        quote.company.address.timeZone
                      )
                    }}
                  </span>
                  <span class="charge-amount">
                    {{ currencyFilter(getAmountDueLaterForTrip(trip)) }}
                  </span>
                </div>

                <div class="trip-charge payment-box payment-box-due-today">
                  <span>
                    {{ parseInt(trip.depositPercentage, 10) }}% Deposit Due
                    Today
                  </span>
                  <span class="charge-amount">
                    {{ currencyFilter(getAmountDueTodayForTrip(trip)) }}
                  </span>
                </div>
              </template>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </section>

    <br />
    <br />

    <section class="checkout-provider-terms-and-conditions">
      <h3>Terms &amp; Conditions for Transportation Reservations</h3>

      <div>
        {{ terms }}
        <br />
        <br />
      </div>
    </section>

    <br />
    <br />

    <v-form
      ref="form"
      v-model="formIsValid"
      class="checkout-provider-payment-form-credit-card"
      @submit.prevent="submit"
    >
      <section class="checkout-provider-payment">
        <v-layout wrap>
          <v-flex
            xs12
            sm6
            mb-5
            :class="{ 'pr-3': $vuetify.breakpoint.smAndUp }"
            class="checkout-provider-payment-form"
          >
            <v-btn-toggle
              v-model="activePaymentMethod"
              mandatory
              class="elevation-0"
            >
              <v-btn
                v-if="paymentMethodAllowed('credit_card')"
                value="credit_card"
              >
                Credit Card
              </v-btn>
              <v-btn v-if="paymentMethodAllowed('check')" value="check">
                Check/ACH
              </v-btn>
            </v-btn-toggle>

            <v-card class="solid elevation-0">
              <v-card-text>
                <template
                  v-if="activePaymentMethod === 'credit_card'"
                >
                  <h2>Card Information</h2>
                  <v-layout row no-gutters>
                    <v-flex xs12>
                      <label for="cardName">Name</label>
                      <v-text-field
                        id="cardName"
                        v-model="cardName.value"
                        :rules="cardName.rules"
                        required
                        validate-on-blur
                        flat
                        solo
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row no-gutters>
                    <v-flex x12>
                      <label for="cardNumber">Card Number</label>
                      <v-text-field
                        id="cardNumber"
                        ref="cardNumber"
                        v-mask="cardNumberMask"
                        :value="cardNumber.value"
                        :rules="cardNumber.rules"
                        :error-messages="cardNumber.errorMessages"
                        required
                        validate-on-blur
                        flat
                        solo
                        @input="(value) => (cardNumber.value = value)"
                        @focus="
                          cardNumber.errorMessages = null
                          paymentServiceError = ''
                        "
                      >
                        <template #append>
                          <v-icon
                            class="fade-in"
                            style="
                              width: 38px;
                              height: 38px;
                              margin-right: 12px;
                            "
                          >
                            $vuetify.icons.creditcard-{{ cardType }}
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row no-gutters>
                    <v-flex xs3 pr-3>
                      <label for="cardExpirationMonth">
                        Exp.
                        <br v-if="$vuetify.breakpoint.xs" />
                        Month
                      </label>

                      <div
                        v-if="$vuetify.breakpoint.smAndUp"
                        id="cardExpirationMonth-container"
                        style="margin-top: 5px"
                      >
                        <v-select
                          id="cardExpirationMonth"
                          v-model="cardExpirationMonth.value"
                          name="cardExpirationMonth"
                          :items="cardExpirationMonth.options"
                          :rules="cardExpirationMonth.rules"
                          :error-messages="cardExpirationMonth.errorMessages"
                          required
                          flat
                          solo
                          validate-on-blur
                          @focus="
                            cardExpirationMonth.errorMessages = null
                            paymentServiceError = ''
                          "
                        />
                      </div>

                      <div v-else class="select-native-wrapper">
                        <select
                          id="cardExpirationMonth"
                          v-model="cardExpirationMonth.value"
                          name="cardExpirationMonth"
                          class="card-expiration-month-native select-native"
                          required
                          :rules="cardExpirationMonth.rules"
                        >
                          <option
                            v-for="(option,
                            optionIndex) in cardExpirationMonth.options"
                            :key="`card-expiration-month-option-${optionIndex}`"
                            :value="option"
                          >
                            {{ option }}
                          </option>
                        </select>
                        <v-icon>arrow_drop_down</v-icon>
                      </div>
                    </v-flex>

                    <v-flex xs4 pr-2>
                      <label for="cardExpirationYear">
                        Exp.
                        <br v-if="$vuetify.breakpoint.xs" />
                        Year
                      </label>

                      <div
                        v-if="$vuetify.breakpoint.smAndUp"
                        id="cardExpirationYear-container"
                        style="margin-top: 5px"
                      >
                        <v-select
                          id="cardExpirationYear"
                          v-model="cardExpirationYear.value"
                          name="cardExpirationYear"
                          :items="cardExpirationYear.options"
                          :rules="cardExpirationYear.rules"
                          :error-messages="cardExpirationYear.errorMessages"
                          validate-on-blur
                          flat
                          solo
                          required
                          @focus="
                            cardExpirationYear.errorMessages = null
                            paymentServiceError = ''
                          "
                        />
                      </div>

                      <div v-else class="select-native-wrapper">
                        <select
                          id="cardExpirationYear"
                          v-model="cardExpirationYear.value"
                          name="cardExpirationYear"
                          class="select-native"
                          required
                          :rules="cardExpirationYear.rules"
                        >
                          <option
                            v-for="(option,
                            optionIndex) in cardExpirationYear.options"
                            :key="`card-expiration-year-option-${optionIndex}`"
                            :value="option"
                          >
                            {{ option }}
                          </option>
                        </select>
                        <v-icon>arrow_drop_down</v-icon>
                      </div>
                    </v-flex>

                    <v-flex xs5>
                      <label for="cardCVV">
                        Security
                        <br v-if="$vuetify.breakpoint.xs" />
                        Code (CVV)
                      </label>

                      <v-text-field
                        id="cardCVV"
                        v-model="cardCVV.value"
                        v-mask="cardCVV.masks[cardType]"
                        :rules="cardCVV.rules"
                        :error-messages="cardCVV.errorMessages"
                        required
                        validate-on-blur
                        flat
                        solo
                        @focus="
                          cardCVV.errorMessages = null
                          paymentServiceError = ''
                        "
                      />
                    </v-flex>
                  </v-layout>
                  <v-divider />
                  <h2>Billing Address</h2>
                  <v-layout row wrap no-gutters>
                    <v-flex xs12>
                      <label for="address1">Address</label>
                      <v-text-field
                        id="address1"
                        v-model="address1.value"
                        :rules="address1.rules"
                        required
                        validate-on-blur
                        flat
                        solo
                      />
                    </v-flex>

                    <v-flex xs6 style="padding-right: 10px">
                      <label for="city">City</label>
                      <v-text-field
                        id="city"
                        v-model="city.value"
                        :rules="city.rules"
                        required
                        validate-on-blur
                        flat
                        solo
                      />
                    </v-flex>

                    <v-flex xs6 style="padding-left: 10px">
                      <label for="zip">Zip Code</label>
                      <v-text-field
                        id="zip"
                        v-model="zip.value"
                        :rules="zip.rules"
                        required
                        validate-on-blur
                        flat
                        solo
                      />
                    </v-flex>
                  </v-layout>
                </template>
                <template v-else>
                  <p>
                    After completion of this checkout an invoice will be emailed
                    to you with instructions for wire transfer or mailing check.
                  </p>
                  <v-divider />
                  <h2>Billing Address</h2>
                  <v-form
                    ref="form-billing-address"
                    v-model="billingAddressFormIsValid"
                  >
                    <v-layout row wrap no-gutters>
                      <v-flex xs12>
                        <label for="address1">Address</label>
                        <v-text-field
                          id="address1"
                          v-model="address1.value"
                          :rules="address1.rules"
                          required
                          validate-on-blur
                          flat
                          solo
                        />
                      </v-flex>

                      <v-flex xs6 style="padding-right: 10px">
                        <label for="city">City</label>
                        <v-text-field
                          id="city"
                          v-model="city.value"
                          :rules="city.rules"
                          required
                          validate-on-blur
                          flat
                          solo
                        />
                      </v-flex>

                      <v-flex xs6 style="padding-left: 10px">
                        <label for="zip">Zip Code</label>
                        <v-text-field
                          id="zip"
                          v-model="zip.value"
                          :rules="zip.rules"
                          required
                          validate-on-blur
                          flat
                          solo
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </template>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex
            xs12
            sm6
            :class="{ 'pl-3': $vuetify.breakpoint.smAndUp }"
            class="checkout-provider-payment-summary"
          >
            <h2>TOTALS</h2>
            <v-card
              v-for="(trip, tripIndex) in quote.trips"
              :key="`checkout-provider-payment-summary-trip-${tripIndex + 1}`"
              class="payment-summary-card elevation-0"
            >
              <v-card-title>
                <span>
                  Trip {{ tripIndex + 1 }}
                  {{ trip.recurrenceText ? `(${trip.recurrenceText})` : '' }}
                </span>
                <span>
                  {{
                    trip.recurringTripTotal
                      ? currencyFilter(trip.recurringTripTotal)
                      : currencyFilter(trip.total)
                  }}
                </span>
              </v-card-title>
              <v-card-text>
                <div v-if="trip.paymentType.key === 'full_payment'">
                  <p class="payment-due-today">
                    <span>Due Today</span>
                    <span>
                      {{
                        trip.recurringTripTotal
                          ? currencyFilter(trip.recurringTripTotal)
                          : currencyFilter(trip.total)
                      }}
                    </span>
                  </p>
                </div>

                <div v-else>
                  <p
                    v-if="trip.paymentType.key === 'down_payment'"
                    class="payment-due-today"
                  >
                    <span>
                      {{ parseInt(trip.depositPercentage, 10) }}% Deposit Due
                      Today
                    </span>
                    <span>
                      {{ currencyFilter(getAmountDueTodayForTrip(trip)) }}
                    </span>
                  </p>
                  <p>
                    <span>
                      Amount Due {{ dateAsShortString(trip.dueDate) }}
                    </span>
                    <span>
                      {{ currencyFilter(getAmountDueLaterForTrip(trip)) }}
                    </span>
                  </p>
                </div>
              </v-card-text>
            </v-card>

            <div class="payment-box payment-box-due-later">
              <span v-if="quote.grandRecurringTotal">Grand Total</span>
              <span v-else>Quote Total</span>
              <span class="charge-amount">
                {{
                  quote.grandRecurringTotal
                    ? currencyFilter(quote.grandRecurringTotal)
                    : currencyFilter(total)
                }}
              </span>
            </div>

            <div class="payment-box payment-box-due-today">
              <span>Total Due Today</span>
              <span class="charge-amount">
                {{ currencyFilter(amountDueToday) }}
              </span>
            </div>
            <div
              v-if="activePaymentMethod !== 'credit_card'"
              class="submit-controls"
            >
              <div>
                <v-form ref="accept-checkbox">
                  <v-checkbox
                    id="checkout-checkbox-agree-to-terms"
                    v-model="agree"
                    :rules="[(v) => !!v || 'You must agree to continue.']"
                    :ripple="false"
                    label="I agree to the Terms and Conditions"
                  />
                </v-form>
              </div>
              <br />
              <v-btn
                id="checkout-button-reserve-now"
                type="submit"
                class="primary"
                :loading="isSubmitting"
                tabindex="8"
              >
                Reserve Now
                <template #loader>
                  <v-progress-circular indeterminate color="white" />
                </template>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </section>

      <br />

      <section>
        <div style="font-size: 20px; text-align: center">
          <span>
            Powered by &nbsp;&nbsp;
            <img
              data-v-d4e28758=""
              src="@/assets/images/logo-coachrail.svg"
              style="position: relative; width: 120px; top: 2px; left: 2px"
            />
          </span>
        </div>
      </section>
    </v-form>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask'

import { DateTime } from 'luxon'
import { currencyFilter, pluralize } from '@/utils/string'
import { phoneFormatFilter } from '@/utils/phone'

const CUSTOMER_NOTE_TYPE = 2

export default {
  directives: {
    mask,
  },
  props: {
    paymentGateway: { type: Object, default: () => undefined },
    quote: {
      type: Object,
      default: () => ({
        company: {
          address: {},
        },
        customer: {},
        trips: [],
        notes: [],
      }),
    },
    terms: {
      type: String,
      default: '',
    },
    isSubmitting: {
      type: Boolean,
    },
  },
  data() {
    return {
      formIsValid: false,
      billingAddressFormIsValid: false,
      activePaymentMethod: '',
      stopNotes: this.quote.trips.map((trip) => {
        let tripStops = [...trip.stops]
        tripStops = tripStops.map((stop) => ({ active: false }))
        return { stops: tripStops }
      }),
      availablePaymentMethods: [
        {
          text: 'Credit Card',
          value: 'credit_card',
        },
        {
          text: 'Check',
          value: 'check',
        },
      ],
      agree: false,
      firstName: {
        value: '',
        rules: [(v) => !!v || 'First name is required'],
      },
      lastName: {
        value: '',
        rules: [(v) => !!v || 'Last name is required'],
      },
      email: {
        value: '',
        rules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
      phone: {
        value: '',
        mask: '+1 (###) ###-####',
        rules: [(v) => !!v || 'Phone number is required'],
      },
      cardName: {
        value: '',
        rules: [(v) => !!v || 'Name is required'],
      },
      cardNumber: {
        value: '',
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card number is required',
          (v) => v.length >= 15 || 'Card number is not valid',
        ],
      },
      cardExpirationMonth: {
        value: '',
        rules: [(v) => !!v || 'Card expiration month is required'],
        errorMessages: null,
        options: (() => {
          const months = []
          for (let i = 1; i <= 12; i++) {
            if (i < 10) {
              months.push('0' + i.toString())
            } else {
              months.push(i.toString())
            }
          }
          return months
        })(),
      },
      cardExpirationYear: {
        value: DateTime.local().year.toString(),
        rules: [(v) => !!v || 'Card expiration year is required'],
        errorMessages: null,
        options: (() => {
          const currentYear = DateTime.local().year
          const years = []
          for (let i = 0; i < 10; i++) {
            years.push((currentYear + i).toString())
          }
          return years
        })(),
      },
      cardCVV: {
        value: '',
        masks: {
          default: '###',
          visa: '###',
          mastercard: '###',
          discover: '###',
          amex: '####',
          diners: '###',
        },
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card CVV is required',
          (v) => v.length >= 3 || 'Card CVV is not valid',
        ],
      },
      paymentServiceError: '',
      address1: {
        value: '',
        rules: [(v) => !!v || 'Address is required'],
      },
      zip: {
        value: '',
        rules: [
          (v) => !!v || 'Zip code is required',
          (v) => (v.length >= 5 && v.length <= 10) || 'Zip code is not valid',
        ],
      },
      city: {
        value: '',
        rules: [(v) => !!v || 'City is required'],
      },
    }
  },
  computed: {
    logoForCompany() {
      if (this.quote?.company) {
        return `${this.quote.company.darkLogoUrl}`
      }
      return ''
    },
    customerAccountName() {
      return this.quote?.customer?.customerAccount?.name
    },
    previousTripNotes() {
      let notes = this.quote?.trips?.[0]?.tripNotes
      if (notes) {
        let customerNotes = notes.find((note) => {
          return note.noteType === CUSTOMER_NOTE_TYPE
        })

        let previousNotes = {}
        previousNotes.customerNotes =
          customerNotes?.html ||
          customerNotes?.note ||
          this.quote.trips[0].description

        return previousNotes
      }

      return {}
    },
    total() {
      return this.quote?.trips.reduce(
        (total, trip) => total + parseFloat(trip.total),
        0
      )
    },
    canConvertWithoutPayment() {
      return this.quote?.trips.reduce((canConvert, trip) => {
        return this.getAmountDueTodayForTrip(trip) > 0 ? false : canConvert // If any trip requires payment the quote cannot convert without it.
      }, true)
    },
    amountDueToday() {
      return this.quote?.trips.reduce((total, trip) => {
        return total + parseFloat(this.getAmountDueTodayForTrip(trip))
      }, 0)
    },
    amountDueLater() {
      return this.quote?.trips.reduce((total, trip) => {
        return total + this.getAmountDueLaterForTrip(trip)
      }, 0)
    },

    cardNumberMask() {
      if (this.cardType === 'amex') {
        return '#### ###### #####'
      }
      if (this.cardType === 'diners') {
        return '#### ###### ####'
      }
      return '#### #### #### ####'
    },
    cardType() {
      if (this.cardNumber?.value?.[0] === '5') {
        return 'mastercard'
      } else if (this.cardNumber?.value?.[0] === '4') {
        return 'visa'
      } else if (this.cardNumber?.value?.[0] === '6') {
        return 'discover'
      } else if (
        ['34', '37'].includes(this.cardNumber?.value?.substring(0, 2))
      ) {
        return 'amex'
      } else if (
        ['30', '36', '38'].includes(this.cardNumber?.value?.substring(0, 2))
      ) {
        return 'diners'
      } else {
        return 'default'
      }
    },
    cardNumberRawValue() {
      return this.cardNumber.value.split(' ').join('')
    },
    quoteHasRecurrence() {
      if (!this.quote.trips?.length) {
        return false
      }

      return this.quote.trips.reduce(
        (prev, curr) => prev || curr.hasRecurrence,
        false
      )
    },
    quoteTotalTrips() {
      if (!this.quote.trips?.length) return 0

      const totalTripsReducer = (prev, curr) => {
        if (curr.hasRecurrence && curr.recurrenceTripCount) {
          return prev + curr.recurrenceTripCount
        }
        return prev + 1
      }

      return this.quote.trips.reduce(totalTripsReducer, 0)
    },
  },
  created() {
    if (this.paymentMethodAllowed('credit_card')) {
      this.activePaymentMethod = 'credit_card'
    } else if (this.paymentMethodAllowed('check')) {
      this.activePaymentMethod = 'check'
    }
  },
  methods: {
    currencyFilter,
    phoneFormatFilter,
    pluralize,
    itineraryDate(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString(
        DateTime.DATE_SHORT
      )
    },
    itineraryTime(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
    recurrenceEndDate(trip) {
      return trip?.recurrences?.[0]?.endDate || trip.startDate
    },
    paymentMethodAllowed(type) {
      return this.quote?.trips[0]?.paymentMethods?.find((method) => {
        return method.isAllowed && method.paymentMethodType?.key === type
      })
    },
    dateAsString(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString(
        DateTime.DATETIME_SHORT
      )
    },
    dateAsShortString(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString()
    },
    pickupDateStringForTrip(trip) {
      return this.dateAsShortString(
        trip.stops[0].pickupDatetime,
        trip.stops[0].address.timeZone
      )
    },
    toggleStopNotes(tripIndex, stopIndex) {
      this.stopNotes[tripIndex].stops[stopIndex].active = !this.stopNotes[
        tripIndex
      ].stops[stopIndex].active
    },
    getChargeTotalForTrip(trip) {
      let total =
        trip.charges.reduce?.((total, charge) => {
          return total + charge.amount
        }, 0) || 0

      if (trip.hasRecurrence && trip.recurrenceTripCount) {
        total = total * trip.recurrenceTripCount
      }
      return total
    },
    getDepositAmountForTrip(trip) {
      return Number(
        Math.round(
          this.getChargeTotalForTrip(trip) *
            (parseFloat(trip.depositPercentage) / 100.0) *
            100.0
        ) / 100.0
      ).toFixed(2)
    },
    getAmountDueTodayForTrip(trip) {
      if (trip.paymentType.key === 'full_payment') {
        return parseFloat(this.getChargeTotalForTrip(trip))
      } else if (trip.paymentType.key === 'down_payment') {
        return this.getDepositAmountForTrip(trip)
      } else {
        return 0
      }
    },
    getAmountDueLaterForTrip(trip) {
      if (trip.paymentType.key === 'full_payment') {
        return 0
      } else if (trip.paymentType.key === 'down_payment') {
        return (
          parseFloat(this.getChargeTotalForTrip(trip)) -
          this.getDepositAmountForTrip(trip)
        )
      } else {
        return parseFloat(this.getChargeTotalForTrip(trip))
      }
    },
    clearErrorMessages() {
      this.cardNumber.errorMessages = null
      this.cardExpirationMonth.errorMessages = null
      this.cardExpirationYear.errorMessages = null
      this.paymentServiceError = ''
    },
    submit() {
      const userCheckedAgree = this.$refs['accept-checkbox'].validate()

      if (this.activePaymentMethod !== 'credit_card') {
        if (this.activePaymentMethod === 'credit_card') {
          const valid = this.$refs.form.validate()
          if (!valid) {
            return
          }
        } else {
          const valid = this.$refs['form-billing-address'].validate()
          if (!valid) {
            return
          }
        }
      } else {
        this.activePaymentMethod = 'credit_card'
      }

      if (!userCheckedAgree) {
        return
      }

      this.clearErrorMessages()
      const { paymentInfo } = {}
      let expMonth = this.cardExpirationMonth.value
      let expYear = this.cardExpirationYear.value
      let typeLabel = this.cardType
      let postalCode = this.zip.value
      let last4 = this.cardNumberRawValue.substr(
        this.cardNumberRawValue.length - 4,
        4
      )
      if (this.activePaymentMethod === 'credit_card' || paymentInfo) {
        expMonth = paymentInfo.exp_month
        expYear = paymentInfo.exp_year
        last4 = paymentInfo.last_4
        postalCode = paymentInfo.billing_postal_code
        typeLabel = paymentInfo.card_brand
      }
      const paymentMethodData = {
        activeMethod: this.activePaymentMethod, // either 'credit_card' or 'check' depending on some tab.
        name: this.cardName.value,
        cardholderName: this.cardName.value,
        cardNumber: this.cardNumberRawValue,
        mask: last4,
        securityCode: this.cardCVV.value,
        exp_date: `${expMonth}/${expYear}`,
        expirationMonth: expMonth,
        expirationYear: expYear,
        type_label: this.activePaymentMethod === 'check' ? 'Check' : typeLabel,
        address: {
          name: '',
          street1: this.address1.value,
          street2: '',
          city: this.city.value,
          state: null,
          postalCode: postalCode,
          lat: null,
          lng: null,
          title: null,
          time_zone: DateTime.local().zoneName,
          country: 'US',
        },
      }
      this.$emit('submit', paymentMethodData)
    },
    getProcessingFeePercentage(trip) {
      const total = trip.total
      const processingFeeCharge = trip.charges.find(
        (charge) => charge.chargeType.key === 'processing_fees'
      )

      if (!total || !processingFeeCharge) {
        return ''
      }
      return (
        (processingFeeCharge.amount / (total - processingFeeCharge.amount)) *
        100
      ).toFixed(2)
    },
  },
}
</script>

<style lang="scss" scoped>
header,
section {
  padding: 10px;
}

a {
  text-decoration: none;
  color: $primary;
}

h2 {
  border-bottom: none;
}

.primary {
  background-color: $primary;
}

.checkout-provider-trip-info-content {
  border: 1px solid $blue-light;

  > div {
    border-top: 1px solid $blue-light;
    &:not(:first-child) {
      padding: 30px;
    }

    p {
      margin: 0;
    }
  }

  &-summary {
    overflow: scroll;
    p {
      margin: 0;
    }
    .flex {
      padding: 30px;
      &:not(:first-child) {
        border-left: 1px solid $blue-light;
      }
      h6,
      p {
        text-align: center;
      }
    }
  }

  &-itinerary {
    overflow: scroll;
    padding-bottom: 0 !important;

    .flex {
      padding: 30px;

      &:first-child {
        flex: 0 0 20px;
      }

      &:nth-child(2) {
        border-bottom: 1px solid $blue-light;
        flex: 1 0 auto;
      }

      &:nth-child(3) {
        border-bottom: 1px solid $blue-light;
        flex: 0 0 150px;
      }

      &:nth-child(4) {
        border-bottom: 1px solid $blue-light;
        flex: 0 0 150px;
      }
    }
    .layout:last-child .flex {
      border-bottom: none;
    }
    .itinerary-number {
      font-weight: bold;
      color: $gray-mid-light;
      font-size: 28px;
    }
  }

  &-contact {
    span:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.checkout-provider-trip-info-rates-charges-container {
  display: flex;
  width: 100%;
}

.checkout-provider-trip-info-rates {
  border: 1px solid $blue-light;
  border-top: none;
  border-right: none;
  width: 50%;
  padding: 30px;

  h3 {
    font-weight: bold;
  }

  .trip-rate {
    padding: 12px;
    padding-left: 0;
  }

  .rate-amount {
    float: right;
  }
}

.checkout-provider-trip-info-charges {
  border: 1px solid $blue-light;
  border-top: none;
  width: 50%;
  padding: 30px;

  h3 {
    font-weight: bold;
  }

  .trip-charge {
    padding: 12px;
    position: relative;
    right: 12px;
    overflow: auto;
  }

  .charge-amount {
    float: right;
  }
}

@media only screen and (max-width: 420px) {
  .checkout-provider-payment-summary {
    .payment-box span:first-child,
    .payment-summary-card span:first-child {
      width: 60% !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .checkout-provider-trip-info-charges {
    margin-left: 0;
  }
}

@media only screen and (min-width: 601px) and (max-width: 700px) {
  .checkout-provider-trip-info-charges {
    margin-left: 40%;
  }
}

.checkout-provider-terms-and-conditions {
  h3 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
  }

  div {
    padding: 24px;
    font-size: 12px;
    border-radius: 12px;
    border: solid 1px $blue-light;
    white-space: pre-wrap;
    max-height: 240px;
    overflow: scroll;
  }
}

.checkout-provider-payment-form {
  h2 {
    margin-bottom: 0.5em;
  }
  .v-card {
    border-radius: 5px;
    background-color: $blue-light;
  }
}

.select-native-wrapper {
  position: relative;

  .v-icon {
    position: absolute;
    right: 3px;
    top: 13px;
  }
}

.select-native {
  border-radius: 5px;
  background: $white;
  height: 40px;
  width: 100%;
  margin-top: 5px;
  padding: 10px;

  &:focus {
    outline: none;
  }
}

.payment-box {
  padding: 16px;
  margin-top: 6px;
  border-radius: 3px;

  &-total {
    background: $black;
    color: $white;
  }

  &-due-later {
    border: 1px solid $black;
  }

  &-due-today {
    background: $success;
    color: $white;
  }
}

.checkout-provider-payment-summary {
  .payment-box {
    font-size: 16px;
    font-weight: bold;
    margin-top: 18px;

    span {
      display: inline-block;
    }

    span:first-child {
      width: 70%;
    }

    span:last-child {
      width: calc(30% - 20px);
      margin-left: 20px;
      text-align: right;
    }
  }

  .payment-summary-card {
    font-size: 16px;
    border: 1px solid $black;
    border-radius: 5px;
    margin-top: 18px;

    span {
      display: inline-block;
    }

    span:first-child {
      width: 70%;
    }

    span:last-child {
      width: calc(30% - 20px);
      margin-left: 20px;
      text-align: right;
    }

    ::v-deep .v-card__title {
      color: $white;
      background-color: $black;
    }

    p {
      &.payment-due-today {
        font-weight: bold;
      }

      &:last-child {
        margin-bottom: 0;
      }

      span:first-child {
        text-align: right;
      }
    }
  }

  .submit-controls {
    margin-top: 18px;
    > * {
      display: block;
      margin: 0 auto;
    }
    > div ::v-deep .v-input__control {
      margin: 0 auto;
    }
    > button {
      font-size: 16px;
      padding: 14px 64px;
      height: auto;
    }
  }
}

.v-label {
  font-size: 14px !important;
}

.icon-arrow {
  transition: transform 0.2s ease-in-out;
  &--flipped {
    transform: rotate(180deg);
    top: 6px;
    left: 5px;
    position: relative;
  }
}

.stop-notes {
  font-size: 12px;
}

.stop-notes-title {
  padding-top: 2px;
  color: $gray;
  font-size: 12px;
}

.v-btn-toggle {
  position: relative;
  top: 3px;
  > .v-btn {
    color: $gray;

    &::before {
      opacity: 0 !important;
    }
    &--active {
      color: $black;
      background-color: $blue-light !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &:first-child {
      border-top-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
    }
  }
}

::v-deep {
  .error--text .v-messages__wrapper .v-messages__message {
    color: $error;
  }

  .v-expansion-panel__header {
    background-color: $blue-light;
  }
  .theme--light.v-expansion-panel .v-expansion-panel__container {
    border-top: 50px solid $white;
  }

  .theme--light.v-input.v-text-field--solo
    > .v-input__control
    > .v-input__slot {
    background-color: $white;
  }
}

.coachrail .theme--light.v-select ::v-deep .v-select__selections {
  color: $black !important;
}

.checkout-provider--quote-info-box {
  border: 1px solid $gray-even-lighter;
  font-size: 16px;
}
</style>
