import { DateTime } from 'luxon'
import { round } from '@/utils/round'

export default {
  computed: {
    total() {
      const rawAmount = this.quote?.trips.reduce(
        (total, trip) => total + parseFloat(trip.total),
        0.0
      )

      return parseFloat(rawAmount.toFixed(2))
    },
    canConvertWithoutPayment() {
      return this.quote?.trips.reduce((canConvert, trip) => {
        return this.getAmountDueTodayForTrip(trip) > 0 ? false : canConvert // If any trip requires payment the quote cannot convert without it.
      }, true)
    },
    amountDueToday() {
      const rawAmount = this.quote?.trips.reduce((total, trip) => {
        return total + parseFloat(this.getAmountDueTodayForTrip(trip))
      }, 0.0)

      return round(rawAmount, 2)
    },
    amountDueLater() {
      const rawAmount = this.quote?.trips.reduce((total, trip) => {
        return total + this.getAmountDueLaterForTrip(trip)
      }, 0.0)

      return round(rawAmount, 2)
    },
  },
  methods: {
    dateAsString(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString(
        DateTime.DATETIME_SHORT
      )
    },
    dateAsShortString(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString()
    },
    pickupDateStringForTrip(trip) {
      return this.dateAsShortString(
        trip.stops[0].pickupDatetime,
        trip.stops[0].address.timeZone
      )
    },
    getChargeTotalForTrip(trip) {
      const rawAmount =
        trip.charges.reduce?.((total, charge) => {
          return total + charge.amount
        }, 0.0) || 0.0

      return round(rawAmount, 2)
    },
    getDepositAmountForTrip(trip) {
      return round(
        parseFloat(
          Math.round(
            this.getChargeTotalForTrip(trip) *
              (parseFloat(trip.depositPercentage) / 100.0) *
              100.0
          ) / 100.0
        ),
        2
      )
    },
    getAmountDueTodayForTrip(trip) {
      if (trip.paymentType?.key === 'full_payment') {
        return this.getChargeTotalForTrip(trip)
      } else if (trip.paymentType?.key === 'down_payment') {
        return this.getDepositAmountForTrip(trip)
      } else {
        return 0.0
      }
    },
    getAmountDueLaterForTrip(trip) {
      if (trip.paymentType.key === 'full_payment') {
        return 0.0
      } else if (trip.paymentType.key === 'down_payment') {
        return (
          this.getChargeTotalForTrip(trip) - this.getDepositAmountForTrip(trip)
        )
      } else {
        return this.getChargeTotalForTrip(trip)
      }
    },
  },
}
