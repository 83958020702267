import * as logger from '@/utils/logger'

export class AcceptService {
  constructor() {
    this.Accept = Accept // eslint-disable-line

    this.authData = {
      clientKey: null,
      apiLoginID: null,
    }
  }

  tokenize(paymentMethodData) {
    return new Promise((resolve, reject) => {
      if (paymentMethodData.activeMethod === 'credit_card') {
        this.tokenizeCreditCard(
          paymentMethodData.cardNumber,
          paymentMethodData.securityCode,
          paymentMethodData.expirationMonth,
          paymentMethodData.expirationYear
        )
          .then((token) => {
            resolve(token)
          })
          .catch((errors) => {
            reject(errors)
          })
      } else if (paymentMethodData.activeMethod === 'ach') {
        this.tokenizeACH(
          paymentMethodData.accountNumber,
          paymentMethodData.routingNumber,
          paymentMethodData.accountType,
          paymentMethodData.name
        )
          .then((token) => {
            resolve(token)
          })
          .catch((errors) => {
            reject(errors)
          })
      } else if (paymentMethodData.activeMethod === 'check') {
        // Do stuff
      } else {
        logger.error(
          'Unknown active type in tokenize()!',
          paymentMethodData.activeMethod
        )
        reject(new Error('Unknown active type in tokenize()!'))
      }
    })
  }

  tokenizeCreditCard(cardNumber, securityCode, month = '', year) {
    return new Promise((resolve, reject) => {
      month = String(month).length < 2 ? `0${month}` : month

      const data = {
        authData: this.authData,
        cardData: {
          cardNumber: cardNumber.replace(/-|\s/g, ''),
          cardCode: securityCode,
          month: String(month),
          year: String(year),
        },
      }

      this.dispatch(data)
        .then((token) => {
          resolve(token)
        })
        .catch((errors) => {
          reject(errors)
        })
    })
  }

  tokenizeACH(accountNumber, routingNumber, accountType, nameOnAccount) {
    return new Promise((resolve, reject) => {
      const data = {
        authData: this.authData,
        bankData: {
          accountNumber: String(accountNumber),
          routingNumber: String(routingNumber),
          nameOnAccount,
          accountType,
        },
      }

      this.dispatch(data)
        .then((token) => {
          resolve(token)
        })
        .catch((errors) => {
          reject(errors)
        })
    })
  }

  dispatch(data) {
    return new Promise((resolve, reject) => {
      this.Accept.dispatchData(data, (response) => {
        if (response.messages.resultCode === 'Ok') {
          if (response.opaqueData && response.opaqueData.dataValue) {
            resolve(response.opaqueData.dataValue)
          } else {
            reject(new Error('Malformed response in dispatch', response))
          }
        } else {
          const errors = []
          const messages = response?.messages?.message
          messages.forEach((message) => {
            errors.push(message.text)
          })

          reject(errors)
        }
      })
    })
  }

  setCredentials(credentials) {
    this.authData = credentials
  }
}
