<template>
  <v-footer block style="background-color: white">
    <v-container justify-center style="max-width: 1100px">
      <v-layout row wrap align-center>
        <v-flex xs6 md2>
          <img
            class="shofur-footer__logo-image"
            width="110px"
            src="~@/assets/images/gogo-charters.png"
          />
        </v-flex>
        <v-flex xs6 md3 style="color: #698592; font-size: 16px">
          Serving 30+ Cities Nationwide
        </v-flex>
        <v-flex xs6 md3 style="color: #2fbdd2">
          <a
            :href="`tel:1-855-826-6770`"
            style="text-decoration: none; color: #2bbcd1; line-height: 1.43"
            :style="
              $vuetify.breakpoint.xs ? 'font-size: 16px;' : 'font-size: 18px;'
            "
          >
            <v-icon
              color="#2bbcd1"
              style="line-height: 1.23"
              :style="
                $vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;'
              "
            >
              phone
            </v-icon>
            1-855-826-6770
          </a>
        </v-flex>
        <v-flex shrink class="px-1">
          <a href="https://twitter.com/GOGOcharters" target="_blank">
            <TwitterIcon style="fill: #2bbcd1" />
          </a>
        </v-flex>
        <v-flex shrink class="px-1">
          <a href="https://www.facebook.com/GOGOcharters/" target="_blank">
            <FacebookIcon style="fill: #2bbcd1" />
          </a>
        </v-flex>
        <v-flex shrink class="px-1">
          <a href="https://www.instagram.com/gogocharters/" target="_blank">
            <InstagramIcon style="fill: #2bbcd1" />
          </a>
        </v-flex>
        <v-divider class="mx-3" vertical />
        <v-flex>
          <a
            style="text-decoration: none; color: #6c717c; line-height: 1.43"
            href="https://gogocharters.com/privacy-policy"
          >
            Privacy Policy
          </a>
          <br />
          <a
            style="text-decoration: none; color: #6c717c; line-height: 1.43"
            href="https://gogocharters.com/privacy-policy"
          >
            Terms & Conditions
          </a>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
import TwitterIcon from '@/icons/TwitterIcon.vue'
import FacebookIcon from '@/icons/FacebookIcon.vue'
import InstagramIcon from '@/icons/InstagramIcon.vue'

export default {
  components: {
    TwitterIcon,
    FacebookIcon,
    InstagramIcon,
  },
}
</script>
