var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-timeline',{attrs:{"align-top":"","dense":"","left":""}},_vm._l((_vm.stops),function(stop){return _c('v-timeline-item',{key:stop.id,attrs:{"color":"white","small":""}},[_c('h4',{staticStyle:{"margin-bottom":"6px","line-height":"24px"}},[(stop.address.title)?_c('span',[_vm._v(" "+_vm._s(stop.address.title)+" "),_c('br')]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("addressPretty")(stop)))])]),_c('div',[(
            stop.dropoffDatetime
              ? stop.dropoffDatetime
              : stop.dropoff_datetime
          )?_c('div',{staticStyle:{"margin-bottom":"6px"}},[_c('v-chip',{staticClass:"trip-type-chip",attrs:{"label":""}},[_vm._v("Dropoff")]),_vm._v("   "),(!_vm.hasRecurrence)?_c('span',[_vm._v(" "+_vm._s(_vm.getSimpleDate( stop.dropoffDatetime ? stop.dropoffDatetime : stop.dropoff_datetime, stop.address.timeZone ? stop.address.timeZone : stop.address.time_zone ))+"   •   ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getSimpleTime( stop.dropoffDatetime ? stop.dropoffDatetime : stop.dropoff_datetime, stop.address.timeZone ? stop.address.timeZone : stop.address.time_zone ))+" ")],1):_vm._e(),(
            !(stop.dropoffDatetime
              ? stop.dropoffDatetime
              : stop.dropoff_datetime) &&
            !(stop.pickupDatetime
              ? stop.pickupDatetime
              : stop.pickup_datetime)
          )?_c('div',[_vm._v("   ")]):_vm._e()]),_c('div',[(
            stop.pickupDatetime ? stop.pickupDatetime : stop.pickup_datetime
          )?_c('div',{staticStyle:{"margin-bottom":"6px"}},[_c('v-chip',{staticClass:"trip-type-chip",attrs:{"label":""}},[_vm._v("Pickup")]),_vm._v("   "),(!_vm.hasRecurrence)?_c('span',[_vm._v(" "+_vm._s(_vm.getSimpleDate( stop.pickupDatetime ? stop.pickupDatetime : stop.pickup_datetime, stop.address.timeZone ? stop.address.timeZone : stop.address.time_zone ))+"   •   ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getSimpleTime( stop.pickupDatetime ? stop.pickupDatetime : stop.pickup_datetime, stop.address.timeZone ? stop.address.timeZone : stop.address.time_zone ))+" ")],1):_vm._e()]),_c('div',{staticStyle:{"height":"20px"}})])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }