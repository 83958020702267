<template>
  <v-footer block style="background-color: white">
    <v-container justify-center style="max-width: 1100px">
      <v-layout row wrap align-center>
        <v-flex xs6 md2>
          <img
            class="shofur-footer__logo-image"
            width="110px"
            src="~@/assets/images/national-charter-bus.png"
          />
        </v-flex>
        <v-flex xs6 md3 style="color: #2fbdd2">
          <a
            :href="`tel:1-844-755-0510`"
            style="
              text-decoration: none;
              color: #008aff;
              line-height: 1.43;
              font-size: 18px;
            "
          >
            <v-icon color="#008aff">phone</v-icon>
            1-844-755-0510
          </a>
        </v-flex>
        <v-flex xs6 md3>
          <v-rating dense color="#008aff" readonly :value="5" />
        </v-flex>
        <v-flex>Overall Rating from 137 unique reviews</v-flex>
        <v-flex shrink class="px-1">
          <a href="https://twitter.com/national_cb" target="_blank">
            <TwitterIcon style="fill: #008aff" />
          </a>
        </v-flex>
        <v-flex shrink class="px-1">
          <a
            href="https://www.facebook.com/NationalBusCharter/"
            target="_blank"
          >
            <FacebookIcon style="fill: #008aff" />
          </a>
        </v-flex>
        <v-divider class="mx-3" vertical />
        <v-flex>
          <a
            style="text-decoration: none; color: #6c717c; line-height: 1.43"
            href="https://www.nationalbuscharter.com/terms-conditions"
          >
            Terms & Conditions
          </a>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
import TwitterIcon from '@/icons/TwitterIcon.vue'
import FacebookIcon from '@/icons/FacebookIcon.vue'

export default {
  components: {
    TwitterIcon,
    FacebookIcon,
  },
}
</script>
