<template>
  <div class="shofur-footer">
    <div class="shofur-footer__primary">
      <div class="shofur-footer__primary__inner container">
        <div class="shofur-footer__main">
          <div class="shofur-footer__links">
            <div class="shofur-footer__link-section">
              <div class="shofur-footer__link-section__name">
                <h3>About Shofur</h3>
              </div>
              <div class="shofur-footer__link-section__list">
                <a
                  v-for="(internalLink,
                  internalIdx) in navigation.internalLinks"
                  :key="internalIdx"
                  class="shofur-footer__link"
                  :href="internalLink.url"
                >
                  {{ internalLink.label }}
                </a>
              </div>
            </div>
            <div class="shofur-footer__link-section">
              <div class="shofur-footer__link-section__name">
                <h3>Locations by City</h3>
              </div>
              <div class="shofur-footer__link-section__list">
                <a
                  v-for="(link, index) in citiesLimited
                    ? navigation.cityLinks.slice(0, 5)
                    : navigation.cityLinks"
                  :key="index"
                  class="shofur-footer__link"
                  :href="link.url"
                >
                  {{ link.label }}
                </a>
              </div>
              <div class="shofur-footer__link-section__toggle">
                <span v-if="citiesLimited" @click="citiesLimited = false">
                  + More
                </span>
                <span v-if="!citiesLimited" @click="citiesLimited = true">
                  - Less
                </span>
              </div>
            </div>
            <div class="shofur-footer__link-section">
              <div class="shofur-footer__link-section__name">
                <h3>Locations by State</h3>
              </div>
              <div class="shofur-footer__link-section__list">
                <a
                  v-for="(stateLink, stateIdx) in statesLimited
                    ? navigation.stateLinks.slice(0, 5)
                    : navigation.stateLinks"
                  :key="stateIdx"
                  class="shofur-footer__link"
                  :href="stateLink.url"
                >
                  {{ stateLink.label }}
                </a>
              </div>
              <div class="shofur-footer__link-section__toggle">
                <span v-if="statesLimited" @click="statesLimited = false">
                  + More
                </span>
                <span v-if="!statesLimited" @click="statesLimited = true">
                  - Less
                </span>
              </div>
            </div>
          </div>
          <div class="shofur-footer__other">
            <div class="shofur-footer__link-section">
              <div class="shofur-footer__link-section__name">
                <h3>Social</h3>
              </div>
              <div class="shofur-footer__link-section__social-list">
                <div class="shofur-footer__social-link">
                  <a href="https://twitter.com/shofur" target="_blank">
                    <img
                      src="https://shofur.com/booking/img/new-img/ico-twitter.png"
                    />
                  </a>
                </div>
                <div class="shofur-footer__social-link">
                  <a target="_blank" href="https://www.facebook.com/RideShofur">
                    <img
                      src="https://shofur.com/booking/img/new-img/ico-facebook.png"
                    />
                  </a>
                </div>
                <div class="shofur-footer__social-link">
                  <a
                    target="_blank"
                    href=" https://plus.google.com/b/110838633822395952308/+SHOFURAtlanta"
                  >
                    <img
                      src="https://shofur.com/booking/img/new-img/ico-googleplus.png"
                    />
                  </a>
                </div>
                <div class="shofur-footer__social-link">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/shofur_bus/"
                  >
                    <img
                      src="https://shofur.com/booking/img/new-img/ico-instagram.png"
                    />
                  </a>
                </div>
                <div class="shofur-footer__social-link">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/shofur"
                  >
                    <img
                      src="https://shofur.com/booking/img/new-img/ico-linkden.png"
                    />
                  </a>
                </div>
                <div class="shofur-footer__social-link">
                  <a href="https://www.pinterest.com/Shofur_Bus">
                    <img
                      src="https://shofur.com/booking/img/new-img/ico-pininterest.png"
                    />
                  </a>
                </div>
                <div class="shofur-footer__social-link">
                  <a href="https://www.youtube.com/user/SHOFURBUS">
                    <img
                      src="https://shofur.com/booking/img/new-img/ico-youtube.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="shofur-footer__link-section">
              <div class="shofur-footer__link-section__name">
                <h3>Contact Us</h3>
              </div>
              <div class="shofur-footer__link-section__body">
                <div>
                  <a
                    class="shofur-footer__contact-link"
                    target="_blank"
                    href="tel:18004368719"
                    style="color: #ccc"
                  >
                    1-800-436-8719
                  </a>
                  <br />
                  <div style="color: gray">Available 24/7</div>
                </div>

                <div style="margin-top: 15px">
                  <StarRating
                    :rating="4.5"
                    half-increments
                    read-only
                    dense
                    :show-rating="false"
                    :max-rating="5"
                    :star-size="15"
                    :border-width="0"
                    :padding="3"
                    :glow="0"
                    glow-color="none"
                    border-color="none"
                  />
                  <div style="color: #ccc">
                    <strong>Overall Rating</strong>
                    :
                    <a
                      href="https://resellerratings.com/store/Shofur"
                      target="_blank"
                      style="color: #00adf5"
                    >
                      9.25 out of 10
                    </a>
                    from 29 unique reviews
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shofur-footer__secondary">
      <div class="shofur-footer__secondary__inner">
        <div class="shofur-footer__legal">
          <!-- <a href="http://shofur.com/term-condition">Terms &amp; Conditions</a>
          <a href="http://shofur.com/app/privacy-policy">Privacy Policy</a> -->
        </div>
        <div class="shofur-footer__logo">
          <img
            class="shofur-footer__logo-image"
            src="~@/assets/images/logo-shofur-icon.png"
          />
        </div>
        <div class="shofur-footer__copyright">
          <!-- &copy; 2013 &mdash; 2019 www.shofur.com, All rights reserved -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navigation from './ShofurNavigation.js'
import StarRating from 'vue-star-rating'

export default {
  components: {
    StarRating,
  },
  data() {
    return {
      navigation,
      statesLimited: true,
      citiesLimited: true,
      star: 9,
    }
  },
}
</script>

<style lang="scss" scoped>
$site-padding: 15px;
$site-gutter: $site-padding * 2;
$site-spacing: $site-padding / 2;

$translucent-stroke: 1px solid rgba($black-base, 0.05);
$card-shadow: 0 0 $site-padding 0 rgba($black-base, 0.09);
$card-addon-shadow: 0 ($site-spacing * 0.5) $site-spacing 0
  rgba($black-base, 0.06);

$mobile-nav-height: 50px;

$nav-drawer-width: 230px;
$inactive-nav-drawer-left: $nav-drawer-width * -1.1;

$small-section-max-width: 485px;

$quote-form-secondary-width: 385px + $site-padding;

a {
  text-decoration: none;
}

.shofur-footer {
  background-color: #2a2a2a;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #999;

  h3 {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    margin-bottom: 25px;
    color: $gray-mid-light;
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    position: relative;
  }

  .shofur-footer__link-section__body {
    font-family: 'Muli', sans-serif !important;
  }

  .shofur-footer__link-section__list {
    font-family: 'Muli', sans-serif;

    > a {
      margin-bottom: 10px;
    }
  }

  &__primary {
    &__inner {
      padding-top: $site-gutter * 2;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      flex: 1 0 200px;
      //width:30%;
    }
  }

  &__other {
    flex: 0 0 25%;
    max-width: 250px;

    .shofur-footer__link-section + .shofur-footer__link-section {
      margin-top: $site-padding;
    }
  }

  .star-review {
    width: 100px;
    border-color: transparent;
    margin: $site-gutter 0 $site-spacing;
  }

  &__contact-link {
    text-align: left;
  }

  &__link-section {
    margin-bottom: $site-gutter;

    &__name {
      font-size: 16px;
      color: $white;
      margin-bottom: $site-gutter;
      position: relative;

      &:after {
        border-bottom: 1px solid #4d4d4d;
        bottom: -10px;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 12px;
      }
    }

    &__body {
      padding-right: 5px;
    }

    &__toggle {
      color: $shofur;
    }
  }

  &__disclaimer {
    color: #999;
    padding: $site-gutter $site-padding;
  }

  &__link {
    display: block;
    margin-bottom: $site-padding;
    &,
    &:link,
    &:active,
    &:visited {
      color: $white;
    }

    &:hover,
    &:focus {
      color: darken($white, 15%);
    }
  }

  &__social-link {
    display: inline-block;
    margin-right: $site-padding * 1.5;
    margin-bottom: $site-padding * 1.5;
  }

  &__secondary {
    background-color: #232323;

    &__inner {
      padding: 10px 15px;
      color: $gray;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a {
        &,
        &:link,
        &:active,
        &:visited {
          color: $gray;
        }

        &:hover,
        &:focus {
          color: darken($gray, 15%);
        }
      text-decoration: underline;
    }
  }

  &__legal,
  &__copyright {
    flex: 1 1 40%;
  }

  &__legal {
    display: flex;
    justify-content: flex-end;
  }

  &__copyright {
    font-size: 0.9em;
  }

  @media screen and (max-width: 768px) {
    &__main {
      display: flex;
      flex-direction: column;

      > * + * {
        margin-top: $site-padding;
      }
    }

    &__secondary {
      &__inner {
        flex-direction: column;

        > * + * {
          margin-top: $site-padding;
        }
      }
    }

    &__legal {
      flex-direction: column;
      align-items: center;

      > * + * {
        margin-top: $site-padding;
      }
    }
  }

  @media screen and (min-width: 992px) {
    &__main {
      display: flex;
      flex-direction: row;

      > * + * {
        margin-left: $site-gutter;
      }
    }

    &__links {
      flex: 0 0 75%;
    }

    &__secondary {
      &__inner {
        flex-direction: row;

        > * + * {
          margin-left: $site-gutter;
        }
      }
    }

    &__legal {
      flex-direction: row;

      > * + * {
        margin-left: $site-padding;
      }
    }
  }
}

.checkout-base .shofur-footer__disclaimer {
  display: none;
}
</style>
