<template>
  <section class="checkout-base">
    <div
      v-if="quote && quote.company && quote.company.name"
      class="checkout-confirmation container"
    >
      <div class="company-image">
        <img
          :src="`${darkLogoUrl}`"
          width="280"
          style="display: block; margin: 0 auto"
        />
      </div>

      <div class="checkout-confirmation__heading">
        We could not find the page you are looking for.
      </div>

      <div class="checkout-confirmation__details">
        For assistance please contact {{ quote.company.name }} at
        <br />
        <a :href="`mailto:${quote.company.email}`">
          {{ quote.company.email }}
        </a>
        or
        <a
          :href="`tel:${quote.company.phone}`"
          style="white-space: nowrap; overflow: hidden"
        >
          {{ phoneFormatFilter(quote.company.phone) }}
        </a>
      </div>

      <br />
      <br />

      <div style="font-size: 20px">
        <span>
          Powered by &nbsp;&nbsp;
          <img
            data-v-d4e28758=""
            src="@/assets/images/logo-coachrail.svg"
            style="position: relative; width: 120px; top: 2px; left: 2px"
          />
        </span>
      </div>
    </div>

    <div v-else class="checkout-confirmation container">
      <div class="checkout-confirmation__heading">
        We could not find the page you are looking for.
      </div>

      <br />
      <br />

      <div style="font-size: 20px">
        <span>
          Powered by &nbsp;&nbsp;
          <img
            data-v-d4e28758=""
            src="@/assets/images/logo-coachrail.svg"
            style="position: relative; width: 120px; top: 2px; left: 2px"
          />
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { phoneFormatFilter } from '@/utils/phone'
import { baseUrl } from '@/utils/env'

export default {
  props: {
    quote: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    darkLogoUrl() {
      if (!this.quote?.company?.darkLogoUrl) {
        return ''
      }
      return `https://${baseUrl(null)}${this.quote.company.darkLogoUrl}`
    },
  },
  methods: {
    phoneFormatFilter,
  },
}
</script>

<style lang="scss" scoped>
.checkout-confirmation {
  box-sizing: border-box;
  color: rgb(62, 67, 81);
  font-family: Lato, sans-serif;
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 20px;
  margin: 0 auto;
  max-width: 800px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 120px;
  text-align: center;
  width: 800px;

  &__symbol {
    box-sizing: border-box;
    color: $primary;
    display: flex;
    flex-direction: row;
    font-size: 85px;
    justify-content: center;
    letter-spacing: 0.56px;
    line-height: 121.433px;
    text-align: center;
  }

  &__heading {
    box-sizing: border-box;
    color: $black;
    font-size: 30px;
    letter-spacing: 0.56px;
    line-height: 42.85px;
    margin-top: 30px;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
  }

  &__details {
    box-sizing: border-box;
    color: $black;
    font-size: 20px;
    letter-spacing: 0.56px;
    line-height: 28.5667px;
    margin-top: 30px;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
    color: $primary;
  }
}

@media only screen and (max-width: 800px) {
  .checkout-confirmation {
    width: auto;
  }
}
</style>
