import { AcceptService } from './acceptService'
import { baseUrl } from '../utils/env'
import axios from 'axios'

export class PaymentGateway {
  constructor() {
    this.acceptService = new AcceptService()
    this.initPromise = null
    this.paymentService = null
  }

  init(company, checkoutPageId = false, paymentGatewayTypeKey = 'auth_net') {
    // Set the payment service
    if (this.initPromise === null) {
      this.initPromise = new Promise((resolve, reject) => {
        const params = {}

        let url = `https://${baseUrl('api')}/paymentGateway/company/${company.id}/default`
        if (checkoutPageId) {
          url = `https://${baseUrl('api')}/paymentGateway/company/${company.id}/checkoutPage/${checkoutPageId}`
        }

        axios
          .get(url, { params })
          .then((response) => {
            if (response && response.data) {
              let paymentGateways = response.data?.paymentGateways
              if (paymentGatewayTypeKey) {
                paymentGateways = paymentGateways.filter((gateway) => gateway.paymentGatewayTypeKey === paymentGatewayTypeKey);
              }

              const payment = paymentGateways[0]

              if (payment.paymentGatewayTypeKey === 'auth_net') {
                this.paymentService = this.acceptService
                this.paymentService.setCredentials({
                  clientKey: payment.clientKey,
                  apiLoginID: payment.apiKey,
                })
              } else if (payment.paymentGatewayTypeKey === 'finix') {
                return resolve({
                  paymentGateway: {
                    key: payment.paymentGatewayTypeKey,
                    id: payment.companyPaymentGatewayId,
                  },
                })
              }
              resolve({
                paymentService: this.paymentService,
                paymentGateway: {
                  key: payment.paymentGatewayTypeKey,
                  id: payment.companyPaymentGatewayId,
                },
              })
            } else {
              reject(new Error('Company has no payment provider.'))
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    return this.initPromise
  }

  tokenize(paymentMethodData, reservationOrQuoteObject) {
    return new Promise((resolve, reject) => {
      if (paymentMethodData.activeMethod === 'check') {
        return resolve({
          tokens: [],
          paymentGateway: null,
        })
      }

      let cpId =
        reservationOrQuoteObject.checkout_page &&
        reservationOrQuoteObject.checkout_page.id
          ? reservationOrQuoteObject.checkout_page.id
          : false
      if (reservationOrQuoteObject.companyCheckoutPageId) {
        cpId = reservationOrQuoteObject.companyCheckoutPageId
      }

      this.init(reservationOrQuoteObject.company, cpId)
        .then(({ paymentGateway }) => {
          const promises = this._createPromises(
            reservationOrQuoteObject,
            paymentMethodData
          )
          Promise.all(promises)
            .then((tokens) => {
              resolve({
                tokens,
                paymentGateway,
              })
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  _createPromises(reservationOrQuoteObject, paymentMethodData) {
    const { trip, trips } = reservationOrQuoteObject
    const array = []
    if (!this.paymentService) {
      return array
    }
    if (trip) {
      array.push(this.paymentService.tokenize(paymentMethodData))
    } else {
      trips.forEach(() => {
        array.push(this.paymentService.tokenize(paymentMethodData))
      })
    }
    return array
  }
}
