export default {
  internalLinks: [
    { label: 'About Us', url: 'https://shofur.com/about-us' },
    { label: 'Press', url: 'https://shofur.com/press' },
    {
      label: 'Bus Rental Guide',
      url: 'https://shofur.com/bus-rental-guide',
    },
    {
      label: 'Conventions & Events',
      url: 'https://shofur.com/conventions-events',
    },
    { label: 'GBTA', url: 'https://shofur.com/gbta' },
    { label: 'Blog', url: 'https://shofur.com/blog' },
    { label: 'Shofurs', url: 'https://shofur.com/shofur-driver' },
    { label: 'Careers', url: 'https://shofur.com/careers' },
    { label: 'Shofur Reviews', url: 'https://shofur.com/reviews' },
    { label: 'Sitemap', url: 'https://shofur.com/sitemap' },
    { label: 'Shofur Market', url: 'https://shofur.com/market' },
  ],
  cityLinks: [
    {
      label: 'Abilene',
      url: 'https://shofur.com/charter-bus/texas/abilene',
    },
    {
      label: 'Albany',
      url: 'https://shofur.com/charter-bus/new-york/albany',
    },
    {
      label: 'Albuquerque',
      url: 'https://shofur.com/charter-bus/new-mexico/albuquerque',
    },
    {
      label: 'Amherst',
      url: 'https://shofur.com/charter-bus/massachusetts/amherst',
    },
    {
      label: 'Anaheim',
      url: 'https://shofur.com/charter-bus/california/anaheim',
    },
    {
      label: 'Athens',
      url: 'https://shofur.com/charter-bus/georgia/athens',
    },
    {
      label: 'Atlanta',
      url: 'https://shofur.com/charter-bus/georgia/atlanta',
    },
    {
      label: 'Atlantic City',
      url: 'https://shofur.com/charter-bus/new-jersey/atlantic-city',
    },
    {
      label: 'Auburn',
      url: 'https://shofur.com/charter-bus/alabama/auburn',
    },
    {
      label: 'Augusta',
      url: 'https://shofur.com/charter-bus/georgia/augusta',
    },
    { label: 'Austin', url: 'https://shofur.com/charter-bus/texas/austin' },
    {
      label: 'Bakersfield',
      url: 'https://shofur.com/charter-bus/california/bakersfield',
    },
    {
      label: 'Baltimore',
      url: 'https://shofur.com/charter-bus/maryland/baltimore',
    },
    {
      label: 'Baton Rouge',
      url: 'https://shofur.com/charter-bus/louisiana/baton-rouge',
    },
    {
      label: 'Binghamton',
      url: 'https://shofur.com/charter-bus/new-york/binghamton',
    },
    {
      label: 'Birmingham',
      url: 'https://shofur.com/charter-bus/alabama/birmingham',
    },
    { label: 'Boise', url: 'https://shofur.com/charter-bus/idaho/boise' },
    {
      label: 'Boston',
      url: 'https://shofur.com/charter-bus/massachusetts/boston',
    },
    {
      label: 'Bozeman',
      url: 'https://shofur.com/charter-bus/montana/bozeman',
    },
    {
      label: 'Bronx',
      url: 'https://shofur.com/charter-bus/new-york/bronx',
    },
    {
      label: 'Brooklyn',
      url: 'https://shofur.com/charter-bus/new-york/brooklyn',
    },
    {
      label: 'Buffalo',
      url: 'https://shofur.com/charter-bus/new-york/buffalo',
    },
    {
      label: 'Burlington',
      url: 'https://shofur.com/charter-bus/vermont/burlington',
    },
    {
      label: 'Calgary',
      url: 'https://shofur.com/charter-bus/alberta/calgary',
    },
    {
      label: 'Charleston',
      url: 'https://shofur.com/charter-bus/south-carolina/charleston',
    },
    {
      label: 'Charlotte',
      url: 'https://shofur.com/charter-bus/north-carolina/charlotte',
    },
    {
      label: 'Chattanooga',
      url: 'https://shofur.com/charter-bus/tennessee/chattanooga',
    },
    {
      label: 'Chicago',
      url: 'https://shofur.com/charter-bus/illinois/chicago',
    },
    {
      label: 'Cincinnati',
      url: 'https://shofur.com/charter-bus/ohio/cincinnati',
    },
    {
      label: 'Cleveland',
      url: 'https://shofur.com/charter-bus/ohio/cleveland',
    },
    {
      label: 'Columbia',
      url: 'https://shofur.com/charter-bus/south-carolina/columbia',
    },
    {
      label: 'Columbus',
      url: 'https://shofur.com/charter-bus/georgia/columbus',
    },
    {
      label: 'Columbus',
      url: 'https://shofur.com/charter-bus/ohio/columbus',
    },
    { label: 'Dallas', url: 'https://shofur.com/charter-bus/texas/dallas' },
    { label: 'Dayton', url: 'https://shofur.com/charter-bus/ohio/dayton' },
    {
      label: 'Daytona Beach',
      url: 'https://shofur.com/charter-bus/florida/daytona-beach',
    },
    {
      label: 'Denver',
      url: 'https://shofur.com/charter-bus/colorado/denver',
    },
    {
      label: 'Des Moines',
      url: 'https://shofur.com/charter-bus/iowa/des-moines',
    },
    {
      label: 'Detroit',
      url: 'https://shofur.com/charter-bus/michigan/detroit',
    },
    {
      label: 'East Hampton',
      url: 'https://shofur.com/charter-bus/new-york/east-hampton',
    },
    {
      label: 'Edmonton',
      url: 'https://shofur.com/charter-bus/alberta/edmonton',
    },
    {
      label: 'El Paso',
      url: 'https://shofur.com/charter-bus/texas/el-paso',
    },
    {
      label: 'Fresno',
      url: 'https://shofur.com/charter-bus/california/fresno',
    },
    {
      label: 'Ft Lauderdale',
      url: 'https://shofur.com/charter-bus/florida/ft-lauderdale',
    },
    {
      label: 'Ft Worth',
      url: 'https://shofur.com/charter-bus/texas/ft-worth',
    },
    {
      label: 'Gainesville',
      url: 'https://shofur.com/charter-bus/florida/gainesville',
    },
    {
      label: 'Galveston',
      url: 'https://shofur.com/charter-bus/texas/galveston',
    },
    {
      label: 'Greensboro',
      url: 'https://shofur.com/charter-bus/north-carolina/greensboro',
    },
    {
      label: 'Greenville',
      url: 'https://shofur.com/charter-bus/south-carolina/greenville',
    },
    {
      label: 'Harrisburg',
      url: 'https://shofur.com/charter-bus/pennsylvania/harrisburg',
    },
    {
      label: 'Hartford',
      url: 'https://shofur.com/charter-bus/connecticut/hartford',
    },
    {
      label: 'Honolulu',
      url: 'https://shofur.com/charter-bus/hawaii/honolulu',
    },
    {
      label: 'Houston',
      url: 'https://shofur.com/charter-bus/texas/houston',
    },
    {
      label: 'Indianapolis',
      url: 'https://shofur.com/charter-bus/indiana/indianapolis',
    },
    {
      label: 'Irvine',
      url: 'https://shofur.com/charter-bus/california/irvine',
    },
    {
      label: 'Jackson',
      url: 'https://shofur.com/charter-bus/mississippi/jackson',
    },
    {
      label: 'Jacksonville',
      url: 'https://shofur.com/charter-bus/florida/jacksonville',
    },
    {
      label: 'Jersey City',
      url: 'https://shofur.com/charter-bus/new-jersey/jersey-city',
    },
    {
      label: 'Joliet',
      url: 'https://shofur.com/charter-bus/illinois/joliet',
    },
    {
      label: 'Jonesboro',
      url: 'https://shofur.com/charter-bus/arkansas/jonesboro',
    },
    {
      label: 'Kansas City',
      url: 'https://shofur.com/charter-bus/missouri/kansas-city',
    },
    {
      label: 'Knoxville',
      url: 'https://shofur.com/charter-bus/tennessee/knoxville',
    },
    { label: 'Laredo', url: 'https://shofur.com/charter-bus/texas/laredo' },
    {
      label: 'Las Vegas',
      url: 'https://shofur.com/charter-bus/nevada/las-vegas',
    },
    {
      label: 'Lexington',
      url: 'https://shofur.com/charter-bus/kentucky/lexington',
    },
    {
      label: 'Little Rock',
      url: 'https://shofur.com/charter-bus/arkansas/little-rock',
    },
    {
      label: 'Long Beach',
      url: 'https://shofur.com/charter-bus/california/long-beach',
    },
    {
      label: 'Long Island',
      url: 'https://shofur.com/charter-bus/new-york/long-island',
    },
    {
      label: 'Los Angeles',
      url: 'https://shofur.com/charter-bus/california/los-angeles',
    },
    {
      label: 'Louisville',
      url: 'https://shofur.com/charter-bus/kentucky/louisville',
    },
    {
      label: 'Lubbock',
      url: 'https://shofur.com/charter-bus/texas/lubbock',
    },
    { label: 'Macon', url: 'https://shofur.com/charter-bus/georgia/macon' },
    {
      label: 'Madison',
      url: 'https://shofur.com/charter-bus/wisconsin/madison',
    },
    {
      label: 'Manchester',
      url: 'https://shofur.com/charter-bus/new-hampshire/manchester',
    },
    {
      label: 'McAllen',
      url: 'https://shofur.com/charter-bus/texas/mcallen',
    },
    {
      label: 'Memphis',
      url: 'https://shofur.com/charter-bus/tennessee/memphis',
    },
    { label: 'Miami', url: 'https://shofur.com/charter-bus/florida/miami' },
    {
      label: 'Milwaukee',
      url: 'https://shofur.com/charter-bus/wisconsin/milwaukee',
    },
    {
      label: 'Minneapolis',
      url: 'https://shofur.com/charter-bus/minnesota/minneapolis',
    },
    {
      label: 'Mobile',
      url: 'https://shofur.com/charter-bus/alabama/mobile',
    },
    {
      label: 'Montgomery',
      url: 'https://shofur.com/charter-bus/alabama/montgomery',
    },
    {
      label: 'Montreal',
      url: 'https://shofur.com/charter-bus/quebec/montreal',
    },
    {
      label: 'Myrtle Beach',
      url: 'https://shofur.com/charter-bus/south-carolina/myrtle-beach',
    },
    {
      label: 'Nashville',
      url: 'https://shofur.com/charter-bus/tennessee/nashville',
    },
    {
      label: 'New Haven',
      url: 'https://shofur.com/charter-bus/connecticut/new-haven',
    },
    {
      label: 'New Orleans',
      url: 'https://shofur.com/charter-bus/louisiana/new-orleans',
    },
    {
      label: 'New York City',
      url: 'https://shofur.com/charter-bus/new-york/new-york-city',
    },
    {
      label: 'Newark',
      url: 'https://shofur.com/charter-bus/new-jersey/newark',
    },
    {
      label: 'Norfolk',
      url: 'https://shofur.com/charter-bus/virginia/norfolk',
    },
    { label: 'Odessa', url: 'https://shofur.com/charter-bus/texas/odessa' },
    {
      label: 'Oklahoma City',
      url: 'https://shofur.com/charter-bus/oklahoma/oklahoma-city',
    },
    {
      label: 'Omaha',
      url: 'https://shofur.com/charter-bus/nebraska/omaha',
    },
    {
      label: 'Orlando',
      url: 'https://shofur.com/charter-bus/florida/orlando',
    },
    {
      label: 'Ottawa',
      url: 'https://shofur.com/charter-bus/ontario/ottawa',
    },
    {
      label: 'Panama City',
      url: 'https://shofur.com/charter-bus/florida/panama-city',
    },
    {
      label: 'Paterson',
      url: 'https://shofur.com/charter-bus/new-jersey/paterson',
    },
    {
      label: 'Pensacola',
      url: 'https://shofur.com/charter-bus/florida/pensacola',
    },
    {
      label: 'Peoria',
      url: 'https://shofur.com/charter-bus/illinois/peoria',
    },
    {
      label: 'Philadelphia',
      url: 'https://shofur.com/charter-bus/pennsylvania/philadelphia',
    },
    {
      label: 'Phoenix',
      url: 'https://shofur.com/charter-bus/arizona/phoenix',
    },
    {
      label: 'Pittsburgh',
      url: 'https://shofur.com/charter-bus/pennsylvania/pittsburgh',
    },
    {
      label: 'Port Canaveral',
      url: 'https://shofur.com/charter-bus/florida/port-canaveral',
    },
    {
      label: 'Portland',
      url: 'https://shofur.com/charter-bus/oregon/portland',
    },
    {
      label: 'Providence',
      url: 'https://shofur.com/charter-bus/rhode-island/providence',
    },
    {
      label: 'Queens',
      url: 'https://shofur.com/charter-bus/new-york/queens',
    },
    {
      label: 'Raleigh',
      url: 'https://shofur.com/charter-bus/north-carolina/raleigh',
    },
    {
      label: 'Rapid City',
      url: 'https://shofur.com/charter-bus/south-dakota/rapid-city',
    },
    { label: 'Reno', url: 'https://shofur.com/charter-bus/nevada/reno' },
    {
      label: 'Richmond',
      url: 'https://shofur.com/charter-bus/virginia/richmond',
    },
    {
      label: 'Rochester',
      url: 'https://shofur.com/charter-bus/new-york/rochester',
    },
    {
      label: 'Rockford',
      url: 'https://shofur.com/charter-bus/illinois/rockford',
    },
    {
      label: 'Sacramento',
      url: 'https://shofur.com/charter-bus/california/sacramento',
    },
    {
      label: 'Salt Lake City',
      url: 'https://shofur.com/charter-bus/utah/salt-lake-city',
    },
    {
      label: 'San Antonio',
      url: 'https://shofur.com/charter-bus/texas/san-antonio',
    },
    {
      label: 'San Diego',
      url: 'https://shofur.com/charter-bus/california/san-diego',
    },
    {
      label: 'San Francisco',
      url: 'https://shofur.com/charter-bus/california/san-francisco',
    },
    {
      label: 'Santa Barbara',
      url: 'https://shofur.com/charter-bus/california/santa-barbara',
    },
    {
      label: 'Santa Fe',
      url: 'https://shofur.com/charter-bus/new-mexico/santa-fe',
    },
    {
      label: 'Savannah',
      url: 'https://shofur.com/charter-bus/georgia/savannah',
    },
    {
      label: 'Seattle',
      url: 'https://shofur.com/charter-bus/washington/seattle',
    },
    {
      label: 'Shreveport',
      url: 'https://shofur.com/charter-bus/louisiana/shreveport',
    },
    {
      label: 'Springfield',
      url: 'https://shofur.com/charter-bus/missouri/springfield',
    },
    {
      label: 'St. Louis',
      url: 'https://shofur.com/charter-bus/missouri/st.-louis',
    },
    {
      label: 'Staten Island',
      url: 'https://shofur.com/charter-bus/new-york/staten-island',
    },
    {
      label: 'Syracuse',
      url: 'https://shofur.com/charter-bus/new-york/syracuse',
    },
    {
      label: 'Tallahassee',
      url: 'https://shofur.com/charter-bus/florida/tallahassee',
    },
    { label: 'Tampa', url: 'https://shofur.com/charter-bus/florida/tampa' },
    {
      label: 'Toronto',
      url: 'https://shofur.com/charter-bus/ontario/toronto',
    },
    {
      label: 'Tucson',
      url: 'https://shofur.com/charter-bus/arizona/tucson',
    },
    {
      label: 'Vancouver',
      url: 'https://shofur.com/charter-bus/british-columbia/vancouver',
    },
    {
      label: 'Virginia Beach',
      url: 'https://shofur.com/charter-bus/virginia/virginia-beach',
    },
    {
      label: 'Washington DC',
      url: 'https://shofur.com/charter-bus/district-of-columbia/washington-dc',
    },
    {
      label: 'Wichita',
      url: 'https://shofur.com/charter-bus/kansas/wichita',
    },
    {
      label: 'Wilmington',
      url: 'https://shofur.com/charter-bus/delaware/wilmington',
    },
    {
      label: 'Winnipeg',
      url: 'https://shofur.com/charter-bus/manitoba/winnipeg',
    },
  ],
  stateLinks: [
    { label: 'Alabama', url: 'https://shofur.com/charter-bus/alabama' },
    { label: 'Alberta', url: 'https://shofur.com/charter-bus/alberta' },
    { label: 'Arizona', url: 'https://shofur.com/charter-bus/arizona' },
    { label: 'Arkansas', url: 'https://shofur.com/charter-bus/arkansas' },
    {
      label: 'British Columbia',
      url: 'https://shofur.com/charter-bus/british-columbia',
    },
    {
      label: 'California',
      url: 'https://shofur.com/charter-bus/california',
    },
    { label: 'Colorado', url: 'https://shofur.com/charter-bus/colorado' },
    {
      label: 'Connecticut',
      url: 'https://shofur.com/charter-bus/connecticut',
    },
    { label: 'Delaware', url: 'https://shofur.com/charter-bus/delaware' },
    {
      label: 'District of Columbia',
      url: 'https://shofur.com/charter-bus/district-of-columbia',
    },
    { label: 'Florida', url: 'https://shofur.com/charter-bus/florida' },
    { label: 'Georgia', url: 'https://shofur.com/charter-bus/georgia' },
    { label: 'Hawaii', url: 'https://shofur.com/charter-bus/hawaii' },
    { label: 'Idaho', url: 'https://shofur.com/charter-bus/idaho' },
    { label: 'Illinois', url: 'https://shofur.com/charter-bus/illinois' },
    { label: 'Indiana', url: 'https://shofur.com/charter-bus/indiana' },
    { label: 'Iowa', url: 'https://shofur.com/charter-bus/iowa' },
    { label: 'Kansas', url: 'https://shofur.com/charter-bus/kansas' },
    { label: 'Kentucky', url: 'https://shofur.com/charter-bus/kentucky' },
    { label: 'Louisiana', url: 'https://shofur.com/charter-bus/louisiana' },
    { label: 'Manitoba', url: 'https://shofur.com/charter-bus/manitoba' },
    { label: 'Maryland', url: 'https://shofur.com/charter-bus/maryland' },
    {
      label: 'Massachusetts',
      url: 'https://shofur.com/charter-bus/massachusetts',
    },
    { label: 'Michigan', url: 'https://shofur.com/charter-bus/michigan' },
    { label: 'Minnesota', url: 'https://shofur.com/charter-bus/minnesota' },
    {
      label: 'Mississippi',
      url: 'https://shofur.com/charter-bus/mississippi',
    },
    { label: 'Missouri', url: 'https://shofur.com/charter-bus/missouri' },
    { label: 'Montana', url: 'https://shofur.com/charter-bus/montana' },
    { label: 'Nebraska', url: 'https://shofur.com/charter-bus/nebraska' },
    { label: 'Nevada', url: 'https://shofur.com/charter-bus/nevada' },
    {
      label: 'New Hampshire',
      url: 'https://shofur.com/charter-bus/new-hampshire',
    },
    {
      label: 'New Jersey',
      url: 'https://shofur.com/charter-bus/new-jersey',
    },
    {
      label: 'New Mexico',
      url: 'https://shofur.com/charter-bus/new-mexico',
    },
    { label: 'New York', url: 'https://shofur.com/charter-bus/new-york' },
    {
      label: 'North Carolina',
      url: 'https://shofur.com/charter-bus/north-carolina',
    },
    { label: 'Ohio', url: 'https://shofur.com/charter-bus/ohio' },
    { label: 'Oklahoma', url: 'https://shofur.com/charter-bus/oklahoma' },
    { label: 'Ontario', url: 'https://shofur.com/charter-bus/ontario' },
    { label: 'Oregon', url: 'https://shofur.com/charter-bus/oregon' },
    {
      label: 'Pennsylvania',
      url: 'https://shofur.com/charter-bus/pennsylvania',
    },
    { label: 'Quebec', url: 'https://shofur.com/charter-bus/quebec' },
    {
      label: 'Rhode Island',
      url: 'https://shofur.com/charter-bus/rhode-island',
    },
    {
      label: 'South Carolina',
      url: 'https://shofur.com/charter-bus/south-carolina',
    },
    {
      label: 'South Dakota',
      url: 'https://shofur.com/charter-bus/south-dakota',
    },
    { label: 'Tennessee', url: 'https://shofur.com/charter-bus/tennessee' },
    { label: 'Texas', url: 'https://shofur.com/charter-bus/texas' },
    { label: 'Utah', url: 'https://shofur.com/charter-bus/utah' },
    { label: 'Vermont', url: 'https://shofur.com/charter-bus/vermont' },
    { label: 'Virginia', url: 'https://shofur.com/charter-bus/virginia' },
    {
      label: 'Washington',
      url: 'https://shofur.com/charter-bus/washington',
    },
    { label: 'Wisconsin', url: 'https://shofur.com/charter-bus/wisconsin' },
  ],
  navData: {
    buses: {
      label: 'Buses',
      url: 'https://shofur.com/buses',
    },
    learn: {
      label: 'Learn More',
      children: {
        about: {
          label: 'About Us',
          url: 'https://shofur.com/about-us',
        },
        events: {
          label: 'Conventions & Events',
          url: 'https://shofur.com/conventions-events',
        },
        careers: {
          label: 'Careers',
          url: 'https://shofur.com/about-us',
        },
      },
    },
    drivers: {
      label: 'Shofurs',
      url: 'https://shofur.com/shofur-driver',
    },
    contact: {
      label: 'Contact',
      url: 'https://shofur.com/contact-us',
    },
  },
}
