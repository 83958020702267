<template>
  <div style="width: 100%" class="gogo-header">
    <v-container
      class="shofur-header py-2"
      justify-center
      fluid
      style="max-width: 1200px"
    >
      <div class="gogo-header__inner">
        <div class="gogo-header__primary">
          <div class="gogo-header__logo">
            <img
              class="gogo-header__logo-image"
              width="100px"
              src="https://gogocharters.com/img/logo.png"
            />
          </div>
          <div class="gogo-header__toggle">
            <button class="btn btn-default" @click="drawerOpen = !drawerOpen">
              <span class="glyphicon glyphicon-menu-hamburger"></span>
            </button>
          </div>
          <div class="gogo-header__desktop-nav">
            <div class="gogo-header__desktop-links">
              <div
                v-for="(item, idx) in navData"
                :key="idx"
                class="gogo-header__desktop-links"
              >
                <a
                  v-if="item.url"
                  target="_blank"
                  class="btn btn-link"
                  :href="item.url"
                >
                  {{ item.label }}
                </a>
                <div v-if="item.children" class="btn-group">
                  <v-menu bottom class="dropdown-menu">
                    <template #activator="{ on }">
                      <div class="btn btn-link" v-on="on">
                        <span>{{ item.label }}</span>
                        <span class="caret down"></span>
                      </div>
                    </template>
                    <v-list class="list-style">
                      <v-list-title
                        v-for="(child, childIdx) in item.children"
                        :key="childIdx"
                        style="margin-top: 5px; margin-bottom: 5px"
                      >
                        <a
                          target="_blank"
                          style="color: white"
                          :href="child.url"
                        >
                          {{ child.label }}
                        </a>
                      </v-list-title>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div class="gogo-header__agents">
                <a
                  target="_blank"
                  href="tel:1-855-826-6770"
                  class="btn btn-default btn--green"
                >
                  <v-btn
                    :style="`
                      color: white;
                      background-color: ${$cr.theme.gogo};
                      border-radius: 18px;
                    `"
                  >
                    <span>
                      <v-icon style="color: white; font-size: 18px">
                        phone
                      </v-icon>
                      &nbsp;1-855-826-6770
                    </span>
                  </v-btn>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navData: [
        {
          label: 'Buses',
          url: 'https://gogocharters.com/gogo-buses',
        },
        {
          label: 'Group Transportation Services',
          url: 'https://gogocharters.com/gogo-services',
        },
        {
          label: 'Where We GOGO',
          url: 'https://gogocharters.com/where-we-gogo',
        },
        {
          label: 'Contact Us',
          url: 'https://gogocharters.com/contact-us',
        },
      ],
      drawerOpen: false,
    }
  },
}
</script>
<style lang="scss" scoped>
$site-padding: 15px;
$site-gutter: $site-padding * 2;
$site-spacing: $site-padding / 2;

$small-section-max-width: 485px;

$quote-form-secondary-width: 385px + $site-padding;
$padding-base-horizontal: 15px;

.caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 4px solid transparent;
}
.caret.down {
  border-top-color: $white;
  position: relative;
  top: 7px;
  left: 2px;
}

a {
  text-decoration: none;
}

.list-style {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: $black;
}

.gogo-header {
  background-color: $white;
  font-family: 'Montserrat', sans-serif;

  &__inner {
    padding-top: $site-padding;
    padding-bottom: $site-padding;
  }

  &__primary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    flex: 0 1 auto;
    width: 215px;

    &-image {
      display: block;
      width: 124px;
      height: auto;
    }
  }

  .btn {
    border-radius: 0;
    outline: none !important;
  }

  &__desktop {
    &-nav {
      display: flex;
      flex-direction: row;
    }
  }

  &__desktop-links {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    margin-top: 15px;
    .btn-link {
      display: flex;
      color: $black;
        &,
        &:hover,
        &:link,
        &:active,
        &:visited {
          text-decoration: none;
        }
    }
  }

  &__agents {
    margin-left: $site-padding;
  }

  &__toggle {
    .btn {
      padding-left: $padding-base-horizontal;
      padding-right: $padding-base-horizontal;
    }
  }

  &__drawer {
    padding-top: $site-padding;
  }

  &__mobile-link {
    padding: $site-padding 0;

    a {
        &,
        &:link,
        &:active,
        &:visited {
          color: $black;
        }

        &:hover,
        &:focus {
          color: darken($black, 15%);
        }
        &,
        &:hover,
        &:link,
        &:active,
        &:visited {
          text-decoration: none;
        }
      cursor: pointer;
    }
  }

  &__mobile-children {
    padding: $site-padding $site-padding 0;

    a {
      display: block;
        &,
        &:link,
        &:active,
        &:visited {
          color: $white;
        }

        &:hover,
        &:focus {
          color: darken($white, 15%);
        }
        &,
        &:hover,
        &:link,
        &:active,
        &:visited {
          text-decoration: none;
        }
    }

    a + a {
      margin-top: $site-padding;
    }
  }

  @media screen and (max-width: 992px) {
    &__desktop-nav {
      display: none;
    }
  }

  @media screen and (min-width: 1000px) {
    &__toggle,
    &__drawer {
      display: none !important;
    }
  }
}
</style>
