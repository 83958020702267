<template>
  <v-toolbar
    :height="$vuetify.breakpoint.smAndUp ? 68 : 60"
    class="app-toolbar"
    elevation="0"
    flat
    style="margin-bottom: 24px"
  >
    <v-toolbar-title class="app-toolbar-title">
      <a id="toolbar-button-logo" href="https://charterup.com" target="_blank">
        <v-icon style="width: 110px">$vuetify.icons.charterUp</v-icon>
      </a>
    </v-toolbar-title>

    <v-spacer />

    <v-flex shrink>
      <h2 text-xs-right><slot /></h2>
    </v-flex>
  </v-toolbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        {
          name: 'msapplication-TileColor',
          content: '#ffc40d',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.$store.getters.appTitle,
        },
        {
          name: 'application-name',
          content: this.$store.getters.appTitle,
        },
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png',
        },
        {
          rel: 'manifest',
          href: '/site.webmanifest',
        },
        {
          rel: 'mask-icon',
          color: '#5bbad5',
          href: '/safari-pinned-tab.svg',
        },
        {
          rel: 'favicon',
          href: '/favicon.ico',
        },
      ],
    }
  },
  props: {
    blank: {
      type: Boolean,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    sidebar: {
      type: Boolean,
    },
    currentUser: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters([
      'appTitle',
      'isTokenSet',
      'user',
      'profile',
      'unreadQuoteCount',
    ]),
    userEmail() {
      return this.currentUser?.email
    },
  },
}
</script>

<style lang="scss" scoped>
.app-toolbar {
  border-bottom: 1px solid $border-gray;
  box-shadow: none;
  background-color: $white !important;
  box-shadow: none;
  font-family: 'Airbnb Cereal App Medium';
  z-index: 99; // should be one less than the sidebar.

  h2 {
    font-family: 'Airbnb Cereal App Book';
  }

  .app-toolbar-title {
    font-size: 1rem;
    margin: 0 45px 0 0;
  }

  a:not(:first-child) {
    padding: 0 24px;
  }
  a {
    height: 68px;
    font-size: 14px;
    &.app-bar-link {
      color: $gray;
      > span {
        display: flex;
        align-items: center;
        border-bottom: 2px solid transparent;
        height: 100%;
      }
      &:hover,
      &.active {
        color: $gray !important;
        > span {
          border-bottom: 2px solid $gray;
        }
      }
    }
  }

  .profile-link {
    font-family: 'Airbnb Cereal App Medium';
    font-size: 14px;
    &:hover {
      cursor: pointer;
    }
  }
}

.profile-list-item {
  color: $gray !important;

  &::before {
    background: none;
  }
}

::v-deep {
  .v-btn {
    text-transform: none;
    padding: 0px;
  }

  .search-field {
    border: 1px grey solid;
    border-radius: 25px;
    height: 42px;
    max-width: 250px;
    margin: 0 10px 0 0 !important;

    .v-input__control {
      min-height: 42px !important;

      > .v-input__slot {
        background-color: unset !important;
      }
    }
  }
}
</style>
