<template>
  <div>
    <v-timeline align-top dense left>
      <v-timeline-item v-for="stop in stops" :key="stop.id" color="white" small>
        <h4 style="margin-bottom: 6px; line-height: 24px">
          <span v-if="stop.address.title">
            {{ stop.address.title }}
            <br />
          </span>
          <span>{{ stop | addressPretty }}</span>
        </h4>
        <div>
          <div
            v-if="
              stop.dropoffDatetime
                ? stop.dropoffDatetime
                : stop.dropoff_datetime
            "
            style="margin-bottom: 6px"
          >
            <v-chip label class="trip-type-chip">Dropoff</v-chip>
            &nbsp;
            <span v-if="!hasRecurrence">
              {{
                getSimpleDate(
                  stop.dropoffDatetime
                    ? stop.dropoffDatetime
                    : stop.dropoff_datetime,
                  stop.address.timeZone
                    ? stop.address.timeZone
                    : stop.address.time_zone
                )
              }}
              &nbsp; • &nbsp;
            </span>
            {{
              getSimpleTime(
                stop.dropoffDatetime
                  ? stop.dropoffDatetime
                  : stop.dropoff_datetime,
                stop.address.timeZone
                  ? stop.address.timeZone
                  : stop.address.time_zone
              )
            }}
          </div>

          <div
            v-if="
              !(stop.dropoffDatetime
                ? stop.dropoffDatetime
                : stop.dropoff_datetime) &&
              !(stop.pickupDatetime
                ? stop.pickupDatetime
                : stop.pickup_datetime)
            "
          >
            &nbsp;
          </div>
        </div>
        <div>
          <div
            v-if="
              stop.pickupDatetime ? stop.pickupDatetime : stop.pickup_datetime
            "
            style="margin-bottom: 6px"
          >
            <v-chip label class="trip-type-chip">Pickup</v-chip>
            &nbsp;

            <span v-if="!hasRecurrence">
              {{
                getSimpleDate(
                  stop.pickupDatetime
                    ? stop.pickupDatetime
                    : stop.pickup_datetime,
                  stop.address.timeZone
                    ? stop.address.timeZone
                    : stop.address.time_zone
                )
              }}
              &nbsp; • &nbsp;
            </span>
            {{
              getSimpleTime(
                stop.pickupDatetime
                  ? stop.pickupDatetime
                  : stop.pickup_datetime,
                stop.address.timeZone
                  ? stop.address.timeZone
                  : stop.address.time_zone
              )
            }}
          </div>
        </div>
        <div style="height: 20px" />
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  filters: {
    addressPretty(stop) {
      const street1 = `${stop?.address?.street1?.trim()} ` || ''
      const city = stop?.address?.city ? `${stop?.address?.city}, ` : ''
      const state = `${stop.address.state} ` || ''
      const postalCode = stop.address.postalCode || ''
      return `${street1}${city}${state}${postalCode}`
    },
  },
  props: {
    stops: {
      type: Array,
      default: () => [],
    },
    hasRecurrence: {
      type: Boolean,
    },
  },
  data() {
    return {}
  },
  methods: {
    getSimpleDate(date, timeZone) {
      return DateTime.fromISO(date, { zone: timeZone }).toLocaleString(
        DateTime.DATE_SHORT
      )
    },
    getSimpleTime(time, timeZone) {
      return DateTime.fromISO(time, { zone: timeZone }).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.pickup-dropoff-line {
  margin-bottom: 5px;
}

.trip-type-chip {
  justify-content: center;
  width: 72px;
  height: 20px;
  padding-bottom: 1px;
  margin-top: -1px;
  margin-right: 1em;
  font-family: 'Airbnb Cereal App Medium';
  font-size: 12px;
}

::v-deep .v-timeline-item__body {
  padding-left: 6px;
}
</style>
