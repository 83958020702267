<template>
  <div style="width: 100%" class="national-header">
    <v-container class="py-2" fluid justify-center style="max-width: 1200px">
      <div class="national-header__inner">
        <div class="national-header__primary">
          <div class="national-header__logo">
            <img
              class="national-header__logo-image"
              width="100px"
              src="@/assets/images/national-logo.png"
            />
          </div>
          <div class="national-header__toggle">
            <button class="btn btn-default" @click="drawerOpen = !drawerOpen">
              <span class="glyphicon glyphicon-menu-hamburger"></span>
            </button>
          </div>
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="national-header__desktop-links"
          >
            <div
              v-for="(item, idx) in navData"
              :key="idx"
              class="national-header__desktop-links"
            >
              <a
                v-if="item.url"
                target="_blank"
                class="btn btn-link"
                :href="item.url"
                style="font-weight: bold"
              >
                {{ item.label }}
              </a>
              <div v-if="item.children" class="btn-group">
                <v-menu bottom class="dropdown-menu">
                  <template #activator="{ on }">
                    <div class="btn btn-link" v-on="on">
                      <span>{{ item.label }}</span>
                      <span class="caret down"></span>
                    </div>
                  </template>
                  <v-list class="list-style">
                    <v-list-title
                      v-for="(child, childIdx) in item.children"
                      :key="childIdx"
                      style="margin-top: 5px; margin-bottom: 5px"
                    >
                      <a target="_blank" style="color: white" :href="child.url">
                        {{ child.label }}
                      </a>
                    </v-list-title>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="national-header__agents">
              <a
                target="_blank"
                href="tel:1-844-755-0510"
                class="btn btn-default btn--green"
              >
                <v-btn color="#186db5" style="color: white">
                  <v-icon style="color: white; font-size: 18px" class="mr-1">
                    phone
                  </v-icon>
                  1-844-755-0510
                </v-btn>
              </a>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navData: {
        buses: {
          label: 'Bus Types',
          url: 'https://www.nationalbuscharter.com/bus-types',
        },
        trips: {
          label: 'Trip Types',
          url: 'https://www.nationalbuscharter.com/charter-bus-trip-types',
        },
        resources: {
          label: 'Resources',
          url: 'https://www.nationalbuscharter.com/charter-bus-rental-prices',
        },
      },
      drawerOpen: false,
    }
  },
}
</script>
<style lang="scss" scoped>
$site-padding: 15px;
$site-gutter: $site-padding * 2;
$site-spacing: $site-padding / 2;

$small-section-max-width: 485px;

$quote-form-secondary-width: 385px + $site-padding;
$padding-base-horizontal: 15px;

.caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 4px solid transparent;
}
.caret.down {
  border-top-color: $white;
  position: relative;
  top: 7px;
  left: 2px;
}

a {
  text-decoration: none;
}

.list-style {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: $black;
}

.national-header {
  background-color: $white;
  font-family: 'Montserrat', sans-serif;

  &__inner {
    padding-top: $site-padding;
    padding-bottom: $site-padding;
  }

  &__primary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    flex: 0 1 auto;
    width: 215px;

    &-image {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .btn {
    border-radius: 0;
    outline: none !important;
  }

  &__desktop {
    &-nav {
      display: flex;
      flex-direction: row;
    }
  }

  &__desktop-links {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    margin-top: 15px;
    .btn-link {
      display: flex;
      color: #0e459d;
      font-weight: 600;
        &,
        &:hover,
        &:link,
        &:active,
        &:visited {
          text-decoration: none;
        }
    }
  }

  &__agents {
    margin-left: $site-padding;
  }

  &__toggle {
    .btn {
      padding-left: $padding-base-horizontal;
      padding-right: $padding-base-horizontal;
    }
  }

  &__drawer {
    padding-top: $site-padding;
  }

  &__mobile-link {
    padding: $site-padding 0;

    a {
              &,
        &:link,
        &:active,
        &:visited {
          color: $white;
        }

        &:hover,
        &:focus {
          color: darken($white, 15%);
        }
        &,
        &:hover,
        &:link,
        &:active,
        &:visited {
          text-decoration: none;
        }
      cursor: pointer;
    }
  }

  &__mobile-children {
    padding: $site-padding $site-padding 0;

    a {
      display: block;
              &,
        &:link,
        &:active,
        &:visited {
          color: $white;
        }

        &:hover,
        &:focus {
          color: darken($white, 15%);
        }
        &,
        &:hover,
        &:link,
        &:active,
        &:visited {
          text-decoration: none;
        }
    }

    a + a {
      margin-top: $site-padding;
    }
  }

  @media screen and (max-width: 992px) {
    &__desktop-nav {
      display: none;
    }
  }

  @media screen and (min-width: 1000px) {
    &__toggle,
    &__drawer {
      display: none !important;
    }
  }
}
</style>
