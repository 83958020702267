<template>
  <v-card>
    <v-card-title>
      <h2>Trips</h2>
    </v-card-title>
    <v-tabs
      v-model="currentTrip"
      color="gray"
      :style="`background-color: ${$cr.theme.headerGray}`"
    >
      <v-tab
        v-for="(trip, i) in trips"
        :id="`trip-collections-card-tab-${i}`"
        :key="i"
      >
        <span v-if="trip.routeName">{{ trip.routeName }}</span>
        <span v-else>Trip {{ i + 1 }}</span>
      </v-tab>
    </v-tabs>

    <v-card-text style="padding-bottom: 0">
      <v-tabs-items v-model="currentTrip">
        <v-tab-item v-for="(trip, i) in trips" :key="i">
          <TripInfo
            :trip="trip"
            :created-on="createdOn"
            :map="map"
            :itinerary="itinerary"
            :customer-notes="customerNotes"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import TripInfo from '@/components/CharterUpTripInfo.vue'

export default {
  components: {
    TripInfo,
  },
  props: {
    trips: {
      type: Array,
      default: () => [],
    },
    createdOn: {
      type: String,
      default: null,
    },
    map: {
      type: Boolean,
    },
    customerNotes: {
      type: Boolean,
    },
    itinerary: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentTrip: 0,
    }
  },
}
</script>
